import React, { useState } from 'react';
import {
    Box,
    Select, MenuItem,
} from "@mui/material";
const ColumnsOferta = ({ dadosFuncoes, sendSelectedFunc, sendSelectedAcao }) => {
    const [selectedIdPerfil, setSelectedIdPerfil] = useState([]);//[{id:'0', value:'0' }]
    const [selectedAcao, setSelectedAcao] = useState([]);
    /*setSelectedIdPerfil([{
        "7156": "I",
        "7157": "R"
    }])*/
    const arrAcao = [
        {
            "identifier": "N",
            "displayName": "Selecione"
        },
        {
            "identifier": "I",
            "displayName": "Inserir"
        },
        {
            "identifier": "R",
            "displayName": "Remover"
        }
    ];

    const columns = [{
        field: "id",
        headerName: "Id",
        minWidth: 10,
        cellClassName: "name-column--cell",
    },
    {
        field: "nomeOfertaD2l",
        headerName: "Nome",
        minWidth: 130,
        cellClassName: "name-column--cell",
    },
    {
        field: "idPerfilD2l",
        headerName: "Perfil",
        minWidth: 10,
        cellClassName: "name-column--cell",
        renderCell: (params) => {
           
            const handleOnChange = (e) => {
                const selectedValue = e.target.value;
                setSelectedIdPerfil((prevSelectedIdPerfil) => {
                    const newSelectedIdPerfil = { ...prevSelectedIdPerfil, [params.row.id]: selectedValue };
                    sendSelectedFunc(newSelectedIdPerfil); 
                
                    return newSelectedIdPerfil;
                  });
              

            };
            const initialValue = selectedIdPerfil[params.row.id] || params.row.idPerfilD2l || '0';
            return (
                <Box sx={{ color: "black" }}>
                    <Select
                        sx={{
                            '& .MuiSelect-icon': {
                                color: "black",
                            },
                            color: "black"
                        }}
                        value={initialValue}
                        onChange={(e) => handleOnChange(e)}
                    >
                        {dadosFuncoes.map((option) => (
                            <MenuItem key={option.identifier} value={option.identifier}>
                                {option.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            );
        }
    },
    {
        field: "tipo",
        headerName: "Ação",
        minWidth: 20,
        cellClassName: "name-column--cell",
        renderCell: (params) => {
            const handleOnChange = (e) => {
                const selectedValue = e.target.value;
                setSelectedAcao((prevSelected) => {
                    const newSelectedAcao = { ...prevSelected, [params.row.id]: selectedValue };
                    sendSelectedAcao(newSelectedAcao);                   
                    return newSelectedAcao;
                  });

            };
            const initialValue =  selectedAcao[params.row.id] || params.row.tipo ||'N';
            return (
                <Box sx={{ color: "black" }}>
                    <Select
                        sx={{
                            '& .MuiSelect-icon': {
                                color: "black",
                            },
                            color: "black"
                        }}
                        value={initialValue}
                        onChange={(e) => handleOnChange(e)}
                    >
                        {arrAcao.map((option) => (
                            <MenuItem key={option.identifier} value={option.identifier}>
                                {option.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            );
        }

    },
    ];

    return columns;
};

export default ColumnsOferta;