import { cookies } from "../cookies";
import { service } from "../services";
import routes from "../services/routes";

export const loginController = {
  login: async (body) => {
    try {
      const rota = routes.login;

      const result = await service.post(rota, body);
      if (result.logado === true) {
        cookies.setCookie('secureSessionVal', result.accessToken);
        cookies.setCookie('secureRefreshSessionVal', result.refreshToken);
        cookies.setCookie('user', result.usuario.nome);
        cookies.setCookie('iduser', result.usuario.id);
        cookies.setCookie('role', result.usuario.perfil);
        window.location.assign('/dashboard')
      }
      return result;
    } catch (error) {
      throw error;
    }
  }
}