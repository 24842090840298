import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/quill.css';
import { useTheme, TextField, Box, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import { service } from "../../../core/services";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const ModalChave = React.memo(({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [htmlContent, setHtmlContent] = useState('');
    const [dadosCampos, setDadosCampos] = useState([{ descricao: '', valor: '' }]);

    const sanitizeHtml = (html) => {
        return html.replace(/\n/g, '').replace(/>\s+</g, '><');
    };
    const handleChange = (content, delta, source, editor) => {
        const sanitizedHtml = sanitizeHtml(content);
        setHtmlContent(sanitizedHtml);
    };
    const handClose = () => {
        setHtmlContent('');
        onClose();
    }
    const handleEdit = async () => {

        const body = {
            id: dados.id,
            chave: dados.idCurso,
            descricao: dadosCampos[0].descricao,
            valor: (dados.tipo === 'email') ? htmlContent : (dadosCampos[0].valor ==='Valor oculto')?'':dadosCampos[0].valor
        };
        await service.put('parametros/atualizar', body)
        setDadosCampos([{ descricao: '', valor: '' }]);
        setHtmlContent('');
        onClose();
    };
    const handleInputChange = (index, key, value) => {

        setDadosCampos((prevOrgs) => {
            const newValue = [...prevOrgs];
            newValue[index][key] = value;
            return newValue;
        });

    };

    useEffect(() => {
        if (onOpen && dados) {
            if (dados.tipo === 'email') {
                setHtmlContent(dados.valor);
                setDadosCampos([{ descricao: dados.descricao || '', valor: '' }]);
            } else {
                setDadosCampos([{ descricao: dados.descricao || '', valor: dados.valor || '' }]);
            }

        }
    }, [onOpen, dados]);
    return (
        <div>
            <BootstrapDialog
                fullWidth={dados.tipo === 'email'}
                maxWidth='lg'
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: colors.appColor['primary'],
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Parametro
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Typography
                            sx={{ marginBottom: 2 }}
                        >Chave: {dados.chave}
                        </Typography>
                        <TextField
                            fullWidth
                            label="Descrição"
                            value={dadosCampos[0].descricao}
                            onChange={(e) =>
                                handleInputChange(0, 'descricao', e.target.value)

                            }
                            sx={{
                                marginBottom: 2,
                                '& label.Mui-focused': {
                                    color: 'white',
                                },
                                '& .MuiOutlinedInput-root.Mui-focused': {
                                    borderColor: 'white',
                                },
                            }}
                        />
                        {dados.tipo === 'email' ? (<ReactQuill
                            theme="snow" // Pode ser 'snow' ou 'bubble'
                            value={htmlContent}
                            onChange={handleChange}
                        />
                        ) : (


                            <TextField
                                fullWidth
                                label="Valor"
                                value={(dadosCampos[0].valor ==='Valor oculto')?'':dadosCampos[0].valor}
                                onChange={(e) =>
                                    handleInputChange(0, 'valor', e.target.value)

                                }
                                sx={{
                                    '& label.Mui-focused': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        borderColor: 'white',
                                    },
                                }}
                            />

                        )}
                    </Box>
                </DialogContent>
                <DialogActions  sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handClose}   variant="contained">
                        Fechar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEdit}
                        sx={{ color: colors.appColor['branca'], backgroundColor:colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
});

export default ModalChave;