import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import StyleGrid from "../../../components/grid/StyleGrid";
import { service } from '../../../core/services';
import CustomNoRowsOverlay from "../../../components/grid/CustomNoRowsOverlay";
import DeleteIcon from '@mui/icons-material/Delete';
import NewModalConfirm from "../../../components/NewModalConfirm";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const ModalCursoD2L = ({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false)
    const [abirModalConfirm, setAbirModalConfirm] = useState(false)
    const [inscricoes, setinscricoes] = useState([]);
    const [dadosUser, setDadosUser] = useState([]);
    const getInscricoes = async () => {
        try {
            setLoading(true)
            let query = `?idUsuario=${dados.id}`;
            const insc = await service.get(`usuarios/inscricoes-d2l${query}`);
            if (insc.code !== 'ERR_BAD_REQUEST' || insc.code !== 'ERR_BAD_RESPONSE') {
                const rows = insc
                    .filter(data => data.orgUnit.type.name !== 'Grupo')
                    .map((data, index) => ({
                        idUsuario:dados.id,
                        id: data.orgUnit.id,
                        orgUnitName: data.orgUnit.name,
                        orgUnitTypeName: data.orgUnit.type.name,
                        roleName: data.role.name,
                    }));
                setinscricoes(rows)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false)
    };

    const handleClose = () => {
        setinscricoes([])
        onClose();
    };
    const handleCloseModalConfirm = () => {
        setAbirModalConfirm(false);
        getInscricoes();
    };

    const columns = [
        {
            field: "orgUnitName",
            headerName: "Unidade Organizacional",
            minWidth: 200,
            cellClassName: "name-column--cell",
        },
        {
            field: "orgUnitTypeName",
            headerName: "Tipo",
            minWidth: 120,
            cellClassName: "name-column--cell",
        },
        {
            field: "roleName",
            headerName: "Perfil",
            minWidth: 130,
            cellClassName: "name-column--cell",
        },
        {
            field: "acao",
            headerName: "Ação",
            sortable: false,
            minWidth: 20,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const par = {
                        idUsuario: params.row.idUsuario,
                        orgUnitId: params.row.id,
                        email: dados.email,
                        unidade: params.row.orgUnitName,
                        acao: 'cursoD2L'
                    }
                    return handleOpenModalConfirm(par)
                };

                return <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={onClick}
                >
                    <DeleteIcon color="error" />
                </IconButton>
            }
        },
    ];
    const handleOpenModalConfirm = (dados) => {
        setDadosUser(dados);
        setAbirModalConfirm(true);
    }

    useEffect(() => {
        if (onOpen && dados.email)
            getInscricoes();
    }, [dados]);

    return (
        <div>
            <NewModalConfirm onOpen={abirModalConfirm} onClose={handleCloseModalConfirm} dados={dadosUser}> </NewModalConfirm>          
            <BootstrapDialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                //disableEscapeKeyDown={true}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Inscrições D2L
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography
                        sx={{ marginBottom: "5px" }}
                    >Nome: {dados.nome}
                    </Typography>

                    <Box
                        height="50vh"

                        sx={StyleGrid}
                    >
                        <DataGrid rows={inscricoes} columns={columns}
                            loading={loading}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} sx={{ color: colors.appColor['branca'] }}>
                        Fechar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default ModalCursoD2L;
