import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import StyleGrid from "../../../components/grid/StyleGrid";
import { service } from '../../../core/services';
import CustomNoRowsOverlay from "../../../components/grid/CustomNoRowsOverlay";
import DeleteIcon from '@mui/icons-material/Delete';
import NewModalConfirm from "../../../components/NewModalConfirm";
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ModalProrrogarAcesso from "../../../components/modalProrrogarAcesso";
import routes from "../../../core/services/routes";
import LoadingSpinner from "../../../components/LoadingSpinner";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const ModalCursoHotMart = ({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [abirModalConfirm, setAbirModalConfirm] = useState(false);
    const [abirModalProrrogar, setAbirModalProrrogar] = useState(false)
    const [inscricoes, setinscricoes] = useState([]);
    const [dadosUser, setDadosUser] = useState([]);

    const getInscricoes = async () => {
        setLoading(true)
        let query = `?idUsuario=${dados.id}`;
        const insc = await service.get(`usuarios/usuario-cursos${query}`);
        if (insc.code !== 'ERR_BAD_REQUEST' && insc.dados && insc.dados.length > 0) {
            if (insc.dados) {
                const rows = insc.dados[0].cursos;
                setinscricoes(rows)
            }
        } else {
            setinscricoes([])
        }
        setLoading(false);
    };

    const handleClose = () => {
        setinscricoes([])
        onClose();
    };
    const handleCloseModal = () => {
        setAbirModalConfirm(false);
        setAbirModalProrrogar(false);
        getInscricoes();
    };
    const columns = [
        {
            field: "nome",
            headerName: "Nome",
            minWidth: 200,
            cellClassName: "name-column--cell",
        },
        {
            field: "inicio",
            headerName: "Início",
            minWidth: 120,
            cellClassName: "name-column--cell",
        },
        {
            field: "situacao",
            headerName: "Status",
            minWidth: 2,
            with: 2,
            renderCell: (params) => {

                return (
                    <Box
                        width="10%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="right"
                    >

                        {params.row.status ? <CheckCircleOutline color="success" /> : <HighlightOff color="error" />}

                    </Box>
                );
            }
        },
        {
            field: "acao",
            headerName: "Ação",
            sortable: false,
            minWidth: 10,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const par = {
                        idCurso: params.row.id,
                        email: dados.email,
                        idUser: dados.id,
                        nomeCurso: params.row.nome,
                        acao: 'cursoHotmart',
                        status: params.row.status
                    }
                    return handleOpenModal(par)
                };

                return (
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={onClick}
                        >
                            {params.row.status ? <DeleteIcon color="error" /> : <AddOutlinedIcon color="success" />}
                        </IconButton>
                    </Box>
                );
            }
        },
    ];
    const handleOpenModal = (dados) => {
        setDadosUser(dados);
        if (dados.status) {           
            setAbirModalConfirm(true);
        } else {
            setAbirModalProrrogar(true);
        }
    }

    const handleProrrogaAcesso = async (dias) => {
        setLoadingSpinner((prev) => !prev);
        const body = {
            IdCurso: dadosUser.idCurso,
            diasAcesso: dias,
            usuariosProrrogar: [
                {
                    IdUsuario: dadosUser.idUser

                }
            ]
        };
        await service.put(routes.prorrogarAcesso, body)
        setLoadingSpinner((prev) => !prev);

        getInscricoes();
    }

    useEffect(() => {
        if (dados.email)
            getInscricoes();
    }, [dados]);

    return (
        <div>
            <NewModalConfirm onOpen={abirModalConfirm} onClose={handleCloseModal} dados={dadosUser}> </NewModalConfirm>
            <ModalProrrogarAcesso onOpen={abirModalProrrogar} onClose={handleCloseModal} onGetDias={handleProrrogaAcesso} />
            <LoadingSpinner loading={loadingSpinner} />
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Cursos Hotmart
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography
                        sx={{ marginBottom: "5px" }}
                    >Nome: {dados.nome}
                    </Typography>

                    <Box
                        height="50vh"

                        sx={StyleGrid}
                    >
                        <DataGrid
                            rows={inscricoes}
                            columns={columns}
                            disableColumnMenu
                            loading={loading}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} sx={{ color: colors.appColor['branca'] }}>
                        Fechar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default ModalCursoHotMart;
