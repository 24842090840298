import React from 'react';
import { Button, useTheme } from "@mui/material";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Importe o locale do Brasil
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";

class Planilha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dados: props.dados,
        };
    }

    gerarPlanilha = () => {
        dayjs.locale('pt-br'); // Configurar o locale para 'pt-br'

        const { dados } = this.state;
        // Converte os dados para um array de arrays
        const wsData = dados.map(item => [
            item.nome + ' ' + item.sobrenome,
            item.email,
            dayjs(item.dataInicio).format('DD/MM/YYYY HH:mm'),
            dayjs(item.dataTermino).format('DD/MM/YYYY HH:mm'),
            item.ativo ? "Ativo" : "Inativo",
            item.nomeCurso
        ]);
        // Adiciona cabeçalhos
        wsData.unshift([
            'Nome',
            'Email',
            'Data de Início',
            'Data de Término',
            'Status',
            'Nome do Curso'
        ]);

        // Cria uma nova planilha
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Dados");

        // Converte o livro para um arquivo binário
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Converte o ArrayBuffer em Blob
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Faz o download do arquivo
        const nome = 'Dados_' + dayjs(Date.now()).format('DD/MM/YYYY HH:mm') + '.xlsx';
        saveAs(blob, nome);
    }

    render() {
        const colors = tokens('light');
        return (

            <div>
                <Button
                    sx={{
                        backgroundColor: colors.appColor['secondary'],
                        color: colors.appColor['branca'],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        '&:hover': {
                            backgroundColor: colors.appColor['primary'],
                        }
                    }}
                    onClick={this.gerarPlanilha}
                >
                    <DownloadOutlinedIcon sx={{ mr: "10px" }} />

                    Baixar Relatório
                </Button>
            </div>
        );
    }
}

export default Planilha;
