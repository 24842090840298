import React, {useEffect, useState } from 'react';
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { service } from '../../core/services';
import Header from "../../components/Header";
import StyleGrid from "../../components/grid/StyleGrid";
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Columns from "./columns";
import ModalChave  from './modal/modal';
import CustomSnackbar from '../../components/Snackbar';

const Settings = () => {  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [searchTerm, setSearchTerm] = useState('');
    const [parametros, setParametros] = useState([]);
    const [loading, setLoading] = useState(false)
    const [openModalEmail, setOpenModalEmail] = useState(false);
    const [dadosChave, setDadosChave] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); //success, error, warning, info
    const handleOpenModal =(dados)=>{
        setDadosChave(dados);
        setOpenModalEmail((prevModal) => !prevModal);  
    }
    const handleSnackbar = (message, severity) => {
        setSnackbar({
            open: true,
            message: message,
            severity: severity
        });
    };

    const getParametros = async () => {
        setLoading(true)
        try{
        let query = '';
        if (searchTerm !== '') {
            query = `?chave=${searchTerm}`; 
        }
        const parm = await service.get(`parametros${query}`);
        if (parm.code === 'ERR_BAD_REQUEST') {
            setParametros([]);
        } else {
            setParametros(parm);
        }
    } catch (error) {
        handleSnackbar(error.message, 'error');
    }
        setLoading(false)
    };
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            getParametros();
        }
    }
    const handleCloseEmail = () => {
        setOpenModalEmail((prevModal) => !prevModal);
        getParametros();
    }
    useEffect(() => {
        getParametros();
    }, []);
    const columns = Columns({ funcao: handleOpenModal });
    return (        

        <Box m="15px">
            <Header title="Configurações" subtitle="Configurações da API" />
            <ModalChave onOpen={openModalEmail} onClose={handleCloseEmail} dados={dadosChave}></ModalChave>
            <CustomSnackbar
                open={snackbar.open}
                handleClose={() => {
                    setSnackbar({ ...snackbar, open: false });
                }}
                message={snackbar.message}
                severity={snackbar.severity}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper
                    component="div"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: colors.appColor['secondary'] }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Pesqusiar..."
                        inputProps={{ 'aria-label': 'Pesqusiar...' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={keyPress}
                    />
                    <IconButton type="button" sx={{ p: '10px' }}
                        aria-label="search"
                        onClick={getParametros}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={StyleGrid}
            >
                <DataGrid rows={parametros} columns={columns} 
                    loading={loading}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                />
            </Box>
        </Box>
     
    );
};

export default Settings;