const Constants = {
    erroSemUsuarioSelecionado: 'Selecione pelo menos um usuário.',
    confirmInativarUsuarios: 'Tem certeza de que deseja desativar os usuários selecionados?',
    confirmEnviarNovaSenha:'Tem certeza de que deseja enviar nova senha para os usuários selecionados?',
    confirmInativarCursos: 'Tem certeza de que deseja inativar os cursos selecionados?',
    confirmAtivarCursos: 'Tem certeza de que deseja ativar os cursos selecionados?',
    confirmExcluirCursos: 'Tem certeza de que deseja excluir os cursos selecionados?',
    erroSemCursoSelecionado: 'Selecione pelo menos um curso.',
    erroSemOfertaSelecionada: 'Selecione pelo menos uma oferta.',
    erroSemParceiroSelecionado: 'Selecione pelo menos um parceiro.',
    confirmInativarParceiros: 'Tem certeza de que deseja inativar os parceiros selecionados?',
    confirmAtivarParceiros: 'Tem certeza de que deseja ativar os parceiros selecionados?',
    confirmExcluirParceiros: 'Tem certeza de que deseja excluir os parceiros selecionados?',
    erroSemPerfilEAcao: 'Selecione o perfil e a ação para todas as ofertas.',
    erroSemOrg: 'Adicione pelo menos uma oferta.',
  }
  
  export default Constants