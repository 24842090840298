import React, { useEffect, useState } from 'react';
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import StyleGrid from "../../components/grid/StyleGrid";
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Columns from "./columns";
import ColorButton from "../../components/ColorButton";
import { parceiroController } from '../../core/controllers/parceiroController';
import Parceiro from './modal/parceiro';
import CustomSnackbar from '../../components/Snackbar';
import ModalConfirm from '../../components/ModalConfirm';
import Constants from '../../constants';
import LoadingSpinner from '../../components/LoadingSpinner';

const Partners = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [searchTerm, setSearchTerm] = useState('');
    const [parceiros, setParceiros] = useState([]);
    const [dadosParceiro, setDadosParceiro] = useState([]);
    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState({ modalConfirm: false, modalParceiro: false });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); //success, error, warning, info
    const [msgConfirm, setMsgConfirm] = useState('');
    const [rowsSelected, setRowsSelected] = useState([]);
    const [acao, setAcao] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const handleSnackbar = (message, severity) => {
        setSnackbar({
            open: true,
            message: message,
            severity: severity
        });
    };
    const handleOpenModal = (dados) => {
        setDadosParceiro(dados);
        setOpenModal({ modalParceiro: true, modalConfirm: false });
        setAcao(dados.acao);



    }
    const getParceiros = async () => {
        setLoading(true)
        try {
            let query = '';
            if (searchTerm !== '') {
                query = `?nome=${searchTerm}`;
            }
            const par = await parceiroController.getParceiros(query);

            if (par.code === 'ERR_BAD_REQUEST') {
                setParceiros([]);
            } else {
                setParceiros(par);
            }
        } catch (error) {
            handleSnackbar(error.message, 'error');
        }
        setLoading(false)
    };
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            getParceiros();
        }
    }
    const handleCloseModal = () => {
        setOpenModal({ modalParceiro: false, modalConfirm: false });
        setDadosParceiro([]);
        getParceiros();
    }
    useEffect(() => {
        getParceiros();
    }, []);

    const handleConfirmar = async () => {
        setLoadingSpinner((prev) => !prev);
        try {
            let dados;
            let result;
            switch (acao) {
                case 'EXCLUIR':
                    dados = {
                        dadosParceiro: rowsSelected.map(id => ({ idParceiro: id }))
                    };
                    result = await parceiroController.deletarParceiro(dados);
                    
                    break;
                case 'INATIVAR':
                    dados = {
                        status: false,
                        dadosParceiro: rowsSelected.map(id => ({ idParceiro: id }))
                    };
                    result = await parceiroController.alterarStatusParceiro(dados);
                    break;
                case 'ATIVAR':
                    dados = {
                        status: true,
                        dadosParceiro: rowsSelected.map(id => ({ idParceiro: id }))
                    };
                    result = await parceiroController.alterarStatusParceiro(dados);
                    break;
                default:
                    break;
            }
            if (result.status) {
                setSnackbar({ open: true, message: result.mensagem, severity: 'success', duration: 600 });

            } else {
                setSnackbar({ open: true, message: result.mensagem, severity: 'error', duration: 5000  });
            }
        } catch (error) {

        }
        setLoadingSpinner((prev) => !prev);
        getParceiros();
    }

    const columns = Columns({ funcao: handleOpenModal });
    return (


        <Box m="15px">
            <Header title="Parceiros" subtitle="Controle de Parceiros" />
            <Parceiro
                onOpen={openModal.modalParceiro}
                titulo={'Novo Parceiro'}
                onClose={handleCloseModal}
                dados={dadosParceiro}
            />
            <CustomSnackbar
                open={snackbar.open}
                handleClose={() => {
                    setSnackbar({ ...snackbar, open: false });
                }}
                message={snackbar.message}
                severity={snackbar.severity}
            />
            <ModalConfirm
                onOpen={openModal.modalConfirm}
                onClose={handleCloseModal}
                onConfirm={handleConfirmar}
                msg={(rowsSelected.length > 0) ? msgConfirm : Constants.erroSemParceiroSelecionado}
                onTipo={(rowsSelected.length > 0) ? 'C' : 'A'}
            />
            <LoadingSpinner loading={loadingSpinner} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper
                    component="div"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: colors.appColor['secondary'] }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Pesqusiar..."
                        inputProps={{ 'aria-label': 'Pesqusiar...' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={keyPress}
                    />
                    <IconButton type="button" sx={{ p: '10px' }}
                        aria-label="search"
                        onClick={getParceiros}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Box>
            <Box
                m="5px 0 5px 0"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    border: (theme) => `none`,
                    borderRadius: 1,
                    bgcolor: colors.appColor['primary'],
                    color: colors.appColor['secondary'],
                    '& svg': {
                        m: 1.5,
                    },
                    '& hr': {
                        mx: 0.5,
                    },
                }}
            >
                <ColorButton
                    onClick={() => {
                        setOpenModal({ modalParceiro: true });
                    }}
                >
                    Novo Parceiro
                </ColorButton>
                <Divider orientation="vertical" flexItem />
                <ColorButton
                    onClick={() => {
                        setAcao('INATIVAR');
                        setMsgConfirm(Constants.confirmInativarParceiros);
                        setOpenModal({ modalConfirm: true });
                    }}
                >
                    Inativar Parceiro
                </ColorButton>
                <ColorButton
                    onClick={() => {
                        setAcao('ATIVAR');
                        setMsgConfirm(Constants.confirmAtivarParceiros);
                        setOpenModal({ modalConfirm: true });
                    }}
                >
                    Ativar Parceiro
                </ColorButton>
                <ColorButton
                    onClick={() => {
                        setAcao('EXCLUIR');
                        setMsgConfirm(Constants.confirmExcluirParceiros);
                        setOpenModal({ modalConfirm: true });
                    }}>
                    Excluir Parceiro
                </ColorButton>

            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={StyleGrid}
            >
                <DataGrid checkboxSelection rows={parceiros} columns={columns}
                    loading={loading}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowsSelected(newRowSelectionModel);
                    }}
                />
            </Box>
        </Box>

    );
};

export default Partners;