import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ loading }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

LoadingSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LoadingSpinner;