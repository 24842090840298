import axios from 'axios'
import routes from './routes';
import { cookies } from '../cookies';

export const service = {

  // Configurar os cabeçalhos com o token de autorização
  headers: {
    'Authorization': `Bearer ${cookies.getCookie('secureSessionVal')}`,
  },

  // Definir a URL base
  urlApi: process.env.REACT_APP_URL_API,

  post: async (rota, body) => {
    try {
      const result = await axios.post(`${service.urlApi}/${rota}`, body, { headers: service.headers });
      return result.data;
    } catch (err) {
      // Se o erro for "401 Unauthorized", tente renovar o token
      if (err.response && err.response.status === 401) {
        try {
          // Adquira um novo token usando o refreshToken
          const newToken = await renewToken();

          // Atualize o cabeçalho com o novo token
          service.headers['Authorization'] = `Bearer ${newToken}`;

          // Faça a segunda tentativa da solicitação
          const result = await axios.post(`${service.urlApi}/${rota}`, body, { headers: service.headers });
          return result.data;
        } catch (renewError) {
          console.error("Erro ao renovar o token:", renewError);
          throw renewError;
        }
      } else {
        // Se não for um erro "401 Unauthorized", simplesmente relance o erro
        throw err;
      }
    }
  },
  get: async (rota) => {
    try {
      const result = await axios.get(`${service.urlApi}/${rota}`, { headers: service.headers });
      return result.data;
    } catch (err) {
      // Se o erro for "401 Unauthorized", tente renovar o token
      if (err.response && err.response.status === 401) {
        try {
          // Adquira um novo token usando o refreshToken
          const newToken = await renewToken();

          // Atualize o cabeçalho com o novo token
          service.headers['Authorization'] = `Bearer ${newToken}`;

          // Faça a segunda tentativa da solicitação
          const result = await axios.get(`${service.urlApi}/${rota}`, { headers: service.headers });
          return result.data;
        } catch (renewError) {
          console.error("Erro ao renovar o token:", renewError);

          throw renewError;
        }
      } else {
        // Se não for um erro "401 Unauthorized", simplesmente relance o erro
        console.error("Erro:", err);
        throw err;

      }
    }

  },
  put: async (rota, body) => {

    try {
      const result = await axios.put(`${service.urlApi}/${rota}`, body, { headers: service.headers })
      return result.data;
    } catch (err) {
      // Se o erro for "401 Unauthorized", tente renovar o token
      if (err.response && err.response.status === 401) {
        try {
          // Adquira um novo token usando o refreshToken
          const newToken = await renewToken();

          // Atualize o cabeçalho com o novo token
          service.headers['Authorization'] = `Bearer ${newToken}`;

          // Faça a segunda tentativa da solicitação
          const result = await axios.put(`${service.urlApi}/${rota}`, body, { headers: service.headers })
          return result.data;
        } catch (renewError) {
          console.error("Erro ao renovar o token:", renewError);

          throw renewError;
        }
      } else {
        // Se não for um erro "401 Unauthorized", simplesmente relance o erro
        console.error("Erro:", err);
        throw err;

      }
    }

  },
  delete: async (rota, body) => {
    try {
      const result = await axios.delete(`${service.urlApi}/${rota}`, { data: body, headers: service.headers })
      return result.data;
    } catch (err) {
      // Se o erro for "401 Unauthorized", tente renovar o token
      if (err.response && err.response.status === 401) {
        try {
          // Adquira um novo token usando o refreshToken
          const newToken = await renewToken();

          // Atualize o cabeçalho com o novo token
          service.headers['Authorization'] = `Bearer ${newToken}`;

          // Faça a segunda tentativa da solicitação
          const result = await axios.delete(`${service.urlApi}/${rota}`, { data: body, headers: service.headers })
          return result.data;
        } catch (renewError) {
          console.error("Erro ao renovar o token:", renewError);

          throw renewError;
        }
      } else {
        // Se não for um erro "401 Unauthorized", simplesmente relance o erro
        console.error("Erro:", err);
        throw err;

      }
    }

  },

};
async function renewToken() {
  try {
    const body = { accessToken: cookies.getCookie('secureSessionVal'), refreshToken: cookies.getCookie('secureRefreshSessionVal') }
    const result = await axios.post(`${service.urlApi}/${routes.renovaToken}`, body);
    const newToken = result.accessToken;
    cookies.setCookie('secureSessionVal', newToken);
    cookies.setCookie('secureRefreshSessionVal', result.refreshToken);
    return newToken;
  } catch (error) {
    throw error;
  }
};


