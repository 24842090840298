import { Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

const Columns = ({ funcao }) => {

    const handleOpenModal = (dados) => {
        funcao(dados)
    };

    const columns = [
        {
            field: "chave",
            headerName: "Chave",
            minWidth: 200,
            flex: 1,
            cellClassName: "name-column--cellssss",
        },
        {
            field: "descricao",
            headerName: "Descrição",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 260,
            flex: 1,
        },
        {
            field: "valor",
            headerName: "Valor",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 360,
            //  flex: 1, 
        },
        {
            field: "---",
            headerName: "Ação",
            sortable: false,
            minWidth: 20,
            renderCell: (params) => {
                const editar = (e) => {
                    e.stopPropagation();

                    const dados = {
                        chave: params.row.chave,
                        valor: params.row.valor,
                        id: params.row.id,
                        descricao: params.row.descricao,
                        tipo: params.row.chave.toLowerCase().includes('email_')?'email':'chave'
                    }
                    return handleOpenModal(dados);
                };
                return (
                    <Box>
                        <IconButton
                            aria-label="Editar"
                            color="inherit"
                            onClick={editar}
                        >
                            <EditIcon />
                        </IconButton>
                    
                    </Box>
                );
            }
        },
    ];

    return columns;
}
Columns.prototype = {
    funcao: PropTypes.func.isRequired,
}
export default Columns;