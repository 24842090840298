import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../theme";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },

}));
const ModalConfirm = ({ onOpen, onClose, msg, onConfirm, onTipo = 'A' }) => { //A: aviso, C: Confirmação
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClose = () => {

        onClose();
    };
    const handleConfirmar = async () => {
        onConfirm();
        onClose();
    };
    return (
        <div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    },
                    zIndex: 'tooltip'
                }}
            > <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              
                 {onTipo === 'C' ? 'Confirmação' : 'Erro'}
                 </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography
                        sx={{ marginBottom: "5px" }}
                    >
                        {msg ? msg : ''}
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleClose}  variant="contained" >
                        Fechar
                    </Button>
                    {onTipo === 'C' && (
                        <Button onClick={handleConfirmar} variant="contained"  sx={{ color: colors.appColor['branca'], backgroundColor:colors.appColor['secondary'] }}>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </div>
    );

};
export default ModalConfirm;
