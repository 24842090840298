import { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../theme";
import { service } from '../core/services'
import LoadingSpinner from './LoadingSpinner';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },

}));
const NewModalConfirm = ({ onOpen, onClose, dados }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleClose = () => {

        onClose();
    };
    const handleConfirmar = async () => {
        setLoading(true);
        switch (dados.acao) {
            case 'cursoHotmart':
                const bodyCurso = {
                    IdCurso: dados.idCurso,
                    desmatricular: [
                        {
                            IdUsuario: dados.idUser

                        }
                    ]
                };
                await service.put('usuarios/desmatricular-usuario-curso', bodyCurso);
                break;

            case 'cursoD2L':
                const bodyD2L = {
                    IdUsuario: dados.idUsuario,
                    OrgUnitId: dados.orgUnitId
                };
                await service.put('usuarios/desmatricular-usuario-d2l', bodyD2L);
                break;

            default:
                break;
        }
        setLoading(false);
        onClose();

    };
    return (
        <div>

            <BootstrapDialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                //disableEscapeKeyDown={true}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    },
                    zIndex: 'tooltip'
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Atenção
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <LoadingSpinner loading={loading} />
                    <Typography
                        sx={{ marginBottom: "5px" }}
                    >Deseja realmente desmatricular o usário de:
                    </Typography>
                    <Typography
                        sx={{ marginBottom: "2px", fontWeight: "bold" }}
                    >
                        {(() => {
                            switch (dados.acao) {
                                case 'cursoHotmart':
                                    return dados.nomeCurso;
                                case 'cursoD2L':
                                    return dados.unidade;
                                default:
                                    return '';
                            }
                        })()}


                    </Typography>
                </DialogContent>

                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button autoFocus onClick={handleClose} variant="contained" >
                        Fechar
                    </Button>
                    <Button autoFocus onClick={handleConfirmar} variant="contained" sx={{ color: colors.appColor['branca'], backgroundColor: colors.appColor['secondary'] }}>
                        Confirmar
                    </Button>

                </DialogActions>
            </BootstrapDialog>
        </div>
    );

};
export default NewModalConfirm;
