import { useRef } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, TextField, Box, } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const NovaSenha = ({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const formUser = useRef();

    const handleFormSubmit = async (values) => {       
        dados(values);
        onClose();
    };


    const handleClose = () => {
        onClose();
    };
    const checkoutSchema = yup.object().shape({
        senha: yup.string().required("Obrigatório")
            .min(8, "A senha deve ter pelo menos 8 caracteres")
            .max(50, "A senha deve ter no máximo 50 caracteres"),
        confirm: yup.string().required("Obrigatório")
            .oneOf([yup.ref('senha'), null], 'As senhas devem ser iguais')
            .min(8, "A senha deve ter pelo menos 8 caracteres")
            .max(50, "A senha deve ter no máximo 50 caracteres"),
    });
    const initialValues = {
        senha: "",
        confirm: "",
    };


    const handleButtonClick = () => {
        formUser.current.handleSubmit();
    };
    return (
        <div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Nova Senha
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Formik
                        innerRef={formUser}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        label="Nova Senha"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.senha}
                                        name="senha"
                                        error={!!touched.senha && !!errors.senha}
                                        helperText={touched.senha && errors.senha}
                                        sx={{
                                            gridColumn: "span 12",
                                            "& input": {
                                                borderColor: touched.senha && errors.senha ? "red" : undefined,
                                            },
                                            "& label": {
                                                color: touched.senha && errors.senha ? "red" : undefined,
                                            },
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="password"
                                        label="Confirmar Nova Senha"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.confirm}
                                        name="confirm"
                                        error={!!touched.confirm && !!errors.confirm}
                                        helperText={touched.confirm && errors.confirm}
                                        sx={{ gridColumn: "span 12" }}
                                    />

                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleClose} variant="contained">
                        Fechar
                    </Button>
                    <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        color="primary"
                        sx={{ color: colors.appColor['branca'], backgroundColor:colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default NovaSenha;
