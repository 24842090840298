import React, { useEffect, useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from "@mui/x-data-grid";
import {
    Box, useTheme, TextField,
    Button, IconButton,
    Select, MenuItem,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import * as yup from "yup";
import { Formik, Field } from "formik";
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { tokens } from "../../../theme";
import { service } from '../../../core/services';
import ModalCursoD2L from './cursoD2L';
import ColumnsOferta from './columnsOferta';
import CustomNoRowsOverlay from "../../../components/grid/CustomNoRowsOverlay";
import StyleGrid from "../../../components/grid/StyleGrid";
import ColorButton from "../../../components/ColorButton";
import { courseController } from "../../../core/controllers/coursesController";
import ModalConfirm from "../../../components/ModalConfirm";
import Constants from "../../../constants";
import LoadingSpinner from "../../../components/LoadingSpinner";
import CustomSnackbar from "../../../components/Snackbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const NewCourse = React.memo(({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [orgs, setOrgs] = useState([]);
    const [listFuncoes, setListFuncoes] = useState([]);
    const [checked, setChecked] = useState(true);
    const [acao, setAcao] = useState('');
    const [modalCursoD2L, setModalCursoD2L] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [parceiros, setParceiros] = useState([]);
    const [selectedPerfil, setSelectedPerfil] = useState([]);
    const [selectedAcao, setSelectedAcao] = useState([]);
    const [openModal, setOpenModal] = useState({ modalConfirm: false });
    const [msgConfirm, setMsgConfirm] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const formCourse = useRef();
    const [snackbar, setSnackbar] = useState({}); //open: false, message: '', severity: 'info', duration: 6000 


    const initialValues = {
        idParceiro: dados.idParceiro || '0',
        nome: dados.nomeCurso || '',
        idProduto: dados.idProduto || 0,
        duracaoCurso: dados.duracaoDias || 0
    };
    const checkoutSchema = yup.object().shape({
        idParceiro: yup.string()
            .test('is-required', 'Selecione um parceiro válido', (value) => value !== '0')
            .required('Obrigatório'),
        nome: yup.string()
            .required('Obrigatório'),
        idProduto: yup.number()
            .typeError('O idProduto deve ser um número')
            .positive('O idProduto deve ser um número positivo')
            .integer('O idProduto deve ser um número inteiro')
            .test('is-number-and-not-zero', 'O ID do Produto deve ser um número diferente de 0', (value) => {
                const parsedValue = parseFloat(value);
                return !isNaN(parsedValue) && parsedValue !== 0;
            })
            .required('Obrigatório'),
        duracaoCurso: yup.number()
            .typeError('A duração do Curso deve ser um número')
            .integer('A duração do Curso deve ser um número inteiro')
            .required('Obrigatório'),
    });

    const getFuncoes = async () => {
        const funcs = await service.get(`d2l/funcoes`);
        if (funcs.code !== 'ERR_BAD_REQUEST') {
            const rows = funcs.filter(
                (item) =>
                    item.displayName.toLowerCase().includes('aluno') ||
                    item.displayName.toLowerCase().includes('leitura')
            );
            rows.unshift({
                identifier: "0",
                displayName: "Selecione",
                code: null
            });
            setListFuncoes(rows);
        }

    };
    const getParceiros = async () => {
        const parm = await service.get(`parceiros`);

        if (parm.code === 'ERR_BAD_REQUEST') {
            setParceiros([]);
        } else {
            setParceiros(parm);
        }
    };

    const handleAddOrgs = (dados) => {
        const novasOrgs = dados.map((item) => ({
            id: item.id,
            nomeOfertaD2l: item.name,
            idPerfilD2l: '',
            tipo: '',
        }));
        // verifica se já existe
        const orgsNaoExistente = novasOrgs.filter((org) => !orgs.some((existingOrg) => existingOrg.id.toString()  === org.id));
        
        // Atualiza orgs com os novos itens
        setOrgs((prevOrgs) => [...prevOrgs, ...orgsNaoExistente]);
       
    };
    const handleRemoveOrg = (index) => {
        const updatedOrgs = orgs.filter(item => !index.includes(item.id));
        setOrgs(updatedOrgs);
    };
    const handleClick = async () => {
        formCourse.current.handleSubmit();

    }
    const handleFormSubmit = async (values) => {
        if (orgs.length > 0) {
            setLoadingSpinner((prev) => !prev);
            /*  switch (acao) {
                  case 'new':*/

            try {              
                const dadosAtualizados = orgs.map(item => ({
                    ...item,
                    idPerfilD2l: selectedPerfil[item.id] || item.idPerfilD2l || '',
                    tipo: selectedAcao[item.id] || item.tipo || ''
                }));
                const hasEmptyValues = dadosAtualizados.some(item => !item.idPerfilD2l || item.idPerfilD2l === '0' || !item.tipo || item.tipo === 'N');

                if (hasEmptyValues) {
                    setMsgConfirm(Constants.erroSemPerfilEAcao);
                    setOpenModal({ modalConfirm: true });

                } else {
                    const body = {
                        NomeCurso: values.nome,
                        DuracaoDias: values.duracaoCurso,
                        IdProduto: values.idProduto,
                        Ativo: checked,
                        IdParceiro: values.idParceiro,
                        ofertas: dadosAtualizados.map(org => ({
                            orgUnitId: org.id,
                            nomeOfertaD2l: org.nomeOfertaD2l,
                            idPerfilD2l: org.idPerfilD2l,
                            tipo: org.tipo
                        }))
                    };
                    const result = (acao === 'EDITAR') ? await courseController.atualizarCurso(dados.idCurso, body) : await courseController.novoCurso(body);

                    if (result.status) {
                        setSnackbar({ open: true, message: result.mensagem, severity: 'success', duration: 600 });

                    } else {
                        setSnackbar({ open: true, message: result.mensagem, severity: 'error', duration: 5000  });
                    }
                }
            } catch (error) {

            }

            setLoadingSpinner((prev) => !prev);
        } else {
            setMsgConfirm(Constants.erroSemOrg);
            setOpenModal({ modalConfirm: true });
        }

    };

   
    const handleChangeActive = (event) => {
        setChecked(event.target.checked);
    };

    const handleModalCursoD2L = () => {
        setModalCursoD2L((prevModal) => !prevModal);
    }

    const receiveSelectedFunc = (dadosPerfil) => {
        setSelectedPerfil(dadosPerfil);
    }
    const receiveSelectedAcao = (dadosAcao) => {
        setSelectedAcao(dadosAcao);
    }
    useEffect(() => {

        if (onOpen) {
            setOrgs([]);
            setSelectedPerfil({});
            setSelectedAcao({});
            setChecked(dados.ativo !== undefined ? dados.ativo : true);
            getFuncoes();
            getParceiros();
            if (dados && dados.ofertas) {
                setAcao('EDITAR');
                // Mapeia os dados de inscrições para o formato do estado `orgs`
                const novasOrgs = dados.ofertas.map((item) => ({
                    id: item.orgUnitId,
                    nomeOfertaD2l: item.nomeOfertaD2l,
                    idPerfilD2l: item.idPerfilD2l,
                    tipo: item.tipo,
                }));
                // verifica se já existe
                const orgsNaoExistente = novasOrgs.filter((org) => !orgs.some((existingOrg) => existingOrg.id === org.id));

                // Atualiza orgs com os novos itens
                setOrgs((prevOrgs) => [...prevOrgs, ...orgsNaoExistente]);
            } else {
                setAcao('NOVO');
            }
        }
    }, [onOpen, dados]);

    const handleCloseModal = () => {
        setOpenModal({ modalConfirm: false });
    };

    const columns = ColumnsOferta({ dadosFuncoes: listFuncoes, sendSelectedFunc: receiveSelectedFunc, sendSelectedAcao: receiveSelectedAcao });
    return (
        <div>
            <ModalCursoD2L onOpen={modalCursoD2L} onClose={handleModalCursoD2L} addListOrgs={handleAddOrgs} ></ModalCursoD2L>
            <ModalConfirm
                onOpen={openModal.modalConfirm}
                onClose={handleCloseModal}
                msg={msgConfirm}
                onTipo={'A'}
            />
            <CustomSnackbar
                open={snackbar.open}
                handleClose={() => {
                    setSnackbar({ open: false});
                    if (snackbar.severity === 'success') {                       
                        onClose();
                    }

                }}
                message={snackbar.message}
                severity={snackbar.severity}
                duration={snackbar.duration}
            />
            <LoadingSpinner loading={loadingSpinner} />
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: colors.appColor['primary'],
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Novo Curso
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose} // Alterado para usar a propriedade onClose diretamente
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Formik
                        innerRef={formCourse}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <legend>Dados do Parceiro</legend>
                                    <div>
                                        <Box sx={{ color: "white" }}>
                                            <Field
                                                as={Select}
                                                fullWidth
                                                variant="filled"
                                                label="Selecione um parceiro"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.idParceiro}
                                                name="idParceiro"
                                                error={!!touched.idParceiro && !!errors.idParceiro}
                                                helperText={touched.idParceiro && errors.idParceiro}
                                                sx={{ gridColumn: 'span 12' }}
                                            >
                                                <MenuItem value="0">Selecione um parceiro</MenuItem>
                                                {parceiros.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nome}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Box>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Dados do Curso HotMart</legend>
                                    <div>
                                        <TextField
                                            label="Nome do Curso"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.nome}
                                            name="nome"
                                            error={!!touched.nome && !!errors.nome}
                                            helperText={touched.nome && errors.nome}
                                            sx={{
                                                '& label.Mui-focused': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused': {
                                                    borderColor: 'white',
                                                },
                                            }}
                                        />
                                    </div>
                                    <Box
                                        display="grid"
                                        gap="30px"
                                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    >
                                        <TextField
                                            fullWidth
                                            label="Id Produto"
                                            margin="normal"
                                            variant="outlined"
                                            sx={{
                                                gridColumn: "span 2",
                                                '& label.Mui-focused': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused': {
                                                    borderColor: 'white',
                                                },
                                            }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.idProduto}
                                            name="idProduto"
                                            error={!!touched.idProduto && !!errors.idProduto}

                                        />

                                        <TextField
                                            fullWidth
                                            label="Duração em Dias"
                                            margin="normal"
                                            variant="outlined"
                                            sx={{
                                                gridColumn: "span 2",
                                                '& label.Mui-focused': {
                                                    color: 'white',
                                                },
                                                '& .MuiOutlinedInput-root.Mui-focused': {
                                                    borderColor: 'white',
                                                },
                                            }}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.duracaoCurso}
                                            name="duracaoCurso"
                                            error={!!touched.duracaoCurso && !!errors.duracaoCurso}
                                        />

                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            label="Ativo"
                                            control={<Checkbox checked={checked} onChange={handleChangeActive} />}
                                        />
                                    </Box>
                                </fieldset>
                                <div style={{ marginTop: 10 }}>
                                    <fieldset>
                                        <legend>Inscrições D2L</legend>
                                        <Box
                                            m="0 0 5px 0"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: 'fit-content',
                                                border: () => `none`,
                                                borderRadius: 1,
                                                bgcolor: colors.appColor['primary'],
                                                color: colors.appColor['secondary'],
                                                '& svg': {
                                                    m: 1.5,
                                                },
                                                '& hr': {
                                                    mx: 0.5,
                                                },
                                            }}
                                        >
                                            <ColorButton minheight={20} onClick={handleModalCursoD2L}>Adicionar Oferta</ColorButton>
                                            <Divider orientation="vertical" flexItem />
                                            <ColorButton
                                                minheight={20}
                                                onClick={() => {
                                                    if (rowsSelected.length > 0) {
                                                        handleRemoveOrg(rowsSelected);
                                                    } else {
                                                        setMsgConfirm(Constants.erroSemOfertaSelecionada);
                                                        setOpenModal({ modalConfirm: true });
                                                    }

                                                }}
                                            >Remover Oferta</ColorButton>
                                        </Box>

                                        <Box
                                            height="30vh"

                                            sx={StyleGrid}
                                        >

                                            <DataGrid
                                                checkboxSelection
                                                rows={orgs}
                                                columns={columns}
                                                selectionModel={rowsSelected}
                                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                                    setRowsSelected(newRowSelectionModel);
                                                }}
                                                loading={loading}
                                                slots={{
                                                    noRowsOverlay: CustomNoRowsOverlay,
                                                }} />
                                        </Box>
                                    </fieldset>
                                </div>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onClose} variant="contained">
                        Fechar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        sx={{ color: colors.appColor['branca'], backgroundColor: colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
});

export default NewCourse;
