// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar {
    background-color: #eaeaea; /* Cor de fundo desejada */
    /* Adicione outros estilos conforme necessário */
  }`, "",{"version":3,"sources":["webpack://./src/scenes/settings/modal/css/quill.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,0BAA0B;IACrD,gDAAgD;EAClD","sourcesContent":[".ql-toolbar {\r\n    background-color: #eaeaea; /* Cor de fundo desejada */\r\n    /* Adicione outros estilos conforme necessário */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
