import {useTheme } from "@mui/material";
import { tokens } from "../../theme";

const StyleGrid = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    {
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
          color:`${colors.primary[900]} !important`,
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: `${colors.appColor['secondary']} !important`,
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.appColor['creme'],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.appColor['secondary'],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.primary[900]}`,
        },
        "& .MuiDataGrid-menu": {
          backgroundColor: `${colors.appColor['secondary']} !important`,
        },        
        "& .MuiDataGrid-columnHeaderTitleContainerContent span " : {
          color: `${colors.primary[100]} !important`,
        },
      }
    );
};

export default StyleGrid;