import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { service } from '../../core/services'
import Header from "../../components/Header";
import StyleGrid from "../../components/grid/StyleGrid";
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Columns from "./columns";
import NewCourse from './modal/newCourse';
import Users from './modal/users';
import ModalConfirm from "../../components/ModalConfirm";
import Constants from "../../constants";
import { courseController } from "../../core/controllers/coursesController";
import LoadingSpinner from "../../components/LoadingSpinner";
import CustomSnackbar from "../../components/Snackbar";

const Courses = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [cursos, setCursos] = useState([]);
    const [openNewCourse, setOpenNewCourse] = useState(false)
    const [openUsers, setOpenUsers] = useState(false)
    const [dadosCurso, setDadosCurso] = useState([]);
    const [openModal, setOpenModal] = useState({ modalConfirm: false });
    const [rowsSelected, setRowsSelected] = useState([]);
    const [msgConfirm, setMsgConfirm] = useState('');
    const [acao, setAcao] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); //success, error, warning, info

    const handleSnackbar = (message, severity) => {
        setSnackbar({
            open: true,
            message: message,
            severity: severity
        });
    };

    const getCursos = async () => {
        setLoading(true)
        try {
            let query = '';
            if (searchTerm !== '') {
                query = `?nome=${searchTerm}`;
            }

            const cursos = await courseController.getCursos(query);

            if (cursos.code === 'ERR_BAD_REQUEST') {
                setCursos([]);
            } else {
                setCursos(cursos);
            }
        } catch (error) {
            handleSnackbar(error.message, 'error');
        }
        setLoading(false)
    };
    const getDadosCurso = async (idCurso) => {
        try {
            let query = `?IdCurso=${idCurso}`;

            const result = await courseController.getOfertasCursos(query);
            if (result.code === 'ERR_BAD_REQUEST') {
                setDadosCurso([]);
            } else {
                setDadosCurso(result);
            }
        } catch (error) {
            handleSnackbar(error.message, 'error');
        }

    };

    useEffect(() => {
        getCursos();
    }, []);


    const keyPress = (e) => {
        if (e.keyCode === 13) {
            getCursos();
        }
    }
    const ColorButton = styled(Button)(({ theme }) => ({
        color: colors.appColor['branca'],
        backgroundColor: colors.appColor['primary'],
        minHeight: 54,
        '&:hover': {
            backgroundColor: colors.appColor['secondary'],
        },
    }));
    const handleOpenModal = async (dados) => {
        switch (dados.acao) {
            case "visualizar":
                setDadosCurso(dados);
                setOpenUsers((prevUsers) => !prevUsers);
                break;
            case "editar": await getDadosCurso(dados.idCurso);
                setOpenNewCourse((prevOpen) => !prevOpen);
                break;
            default:
                break;
        }

    };
    const handleNewCourse = () => {
        setDadosCurso([]);
        setOpenNewCourse((prevOpen) => !prevOpen);
        getCursos();
    };

    const handleUser = () => {
        setOpenUsers((prevUsers) => !prevUsers);
        getCursos();
    }
    const handleConfirmar = async () => {
        setLoadingSpinner((prev) => !prev);
        let dados;
        switch (acao) {
            case 'EXCLUIR':
                dados = {
                    dadosCurso: rowsSelected.map(id => ({ idCurso: id }))
                };
                await courseController.deleteCurso(dados);
                break;
            case 'INATIVAR':
                dados = {
                    status: false,
                    dadosCurso: rowsSelected.map(id => ({ idCurso: id }))
                };
                await courseController.alterarStatusCurso(dados);
                break;
            case 'ATIVAR':
                dados = {
                    status: true,
                    dadosCurso: rowsSelected.map(id => ({ idCurso: id }))
                };
                await courseController.alterarStatusCurso(dados);
                break;
            default:
                break;
        }
        setLoadingSpinner((prev) => !prev);
        getCursos();
    }
    const handleCloseModal = () => {
        setOpenModal({ modalConfirm: false });
    };
    const columns = Columns({ funcao: handleOpenModal });
    return (

        <Box m="15px">
            <Header title="Cursos" subtitle="Controle de cursos" />
            <NewCourse onOpen={openNewCourse} onClose={handleNewCourse} dados={dadosCurso}></NewCourse>
            <Users onOpen={openUsers} onClose={handleUser} dados={dadosCurso}></Users>
            <ModalConfirm
                onOpen={openModal.modalConfirm}
                onClose={handleCloseModal}
                onConfirm={handleConfirmar}
                msg={(rowsSelected.length > 0) ? msgConfirm : Constants.erroSemCursoSelecionado}
                onTipo={(rowsSelected.length > 0) ? 'C' : 'A'}
            />
            <CustomSnackbar
                open={snackbar.open}
                handleClose={() => {
                    setSnackbar({ ...snackbar, open: false });//{ open: false, message: '', severity: '' });
                }}
                message={snackbar.message}
                severity={snackbar.severity}
            />
            <LoadingSpinner loading={loadingSpinner} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper
                    component="div"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: colors.appColor['secondary'] }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Pesqusiar..."
                        inputProps={{ 'aria-label': 'Pesqusiar...' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={keyPress}
                    />
                    <IconButton type="button" sx={{ p: '10px' }}
                        aria-label="search"
                        onClick={getCursos}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={StyleGrid}
            >
                <Box
                    m="0 0 5px 0"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        border: (theme) => `none`,
                        borderRadius: 1,
                        bgcolor: colors.appColor['primary'],
                        color: colors.appColor['secondary'],
                        '& svg': {
                            m: 1.5,
                        },
                        '& hr': {
                            mx: 0.5,
                        },
                    }}
                >
                    <ColorButton onClick={handleNewCourse} >Novo Curso</ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={() => {
                            setAcao('INATIVAR');
                            setMsgConfirm(Constants.confirmInativarCursos);
                            setOpenModal({ modalConfirm: true });
                        }}
                    >
                        Inativar Curso
                    </ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={() => {
                            setAcao('ATIVAR');
                            setMsgConfirm(Constants.confirmAtivarCursos);
                            setOpenModal({ modalConfirm: true });
                        }}
                    >
                        Ativar Curso
                    </ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={() => {
                            setAcao('EXCLUIR');
                            setMsgConfirm(Constants.confirmExcluirCursos);
                            setOpenModal({ modalConfirm: true });
                        }}>
                        Excluir Curso
                    </ColorButton>

                </Box>
                <DataGrid
                    checkboxSelection
                    rows={cursos}
                    columns={columns}
                    disableColumnMenu
                    loading={loading}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowsSelected(newRowSelectionModel);
                    }}
                />
            </Box>
        </Box>
    );
};

export default Courses;
