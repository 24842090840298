const routes = {
  login: 'autenticar/login',
  renovaToken: 'autenticar/refresh-token',
  curso: 'cursos',
  novoUsuario: 'usuarios/novo-usuario',
  inativarUsuario: 'usuarios/inativar',
  alterarSenhaManual: 'senha/alterar-manual',
  alterarSenhaPorEmail: 'senha/alterar-email',
  inscreverUsuario: 'usuarios/inscrever-usuario',
  desmatricularUsuarioCurso: 'usuarios/desmatricular-usuario-curso',
  prorrogarAcesso: 'usuarios/prorrogar-acesso',
  novoCurso: 'cursos/novo-curso',
  ofertasCurso: 'cursos/ofertas',
  usuariosCursos: 'cursos/usuarios',
  deletaCurso: 'cursos/excluir-curso',
  alteraStatusCurso: 'cursos/alterar-status',
  atualizarCurso: 'cursos/atualizar',
  parceiro: 'parceiros',
  novoParceiro: 'parceiros/novo-parceiro',
  alteraStatusParceiro: 'parceiros/alterar-status',
  deletaParceiro: 'parceiros/excluir-parceiro',
  atualizarParceiro: 'parceiros/atualizar',
  usuario: 'usuarios',
}

export default routes
