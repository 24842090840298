import { useEffect, useState, useRef } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, TextField, Box, Select, MenuItem, } from "@mui/material";
import * as yup from "yup";
import { Formik, Field } from "formik";
import { tokens } from "../../../theme";
import { service } from '../../../core/services'
import routes from '../../../core/services/routes';
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingSpinner from "../../../components/LoadingSpinner";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const NovoUser = ({ onOpen, onClose, dados }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [cursos, setCursos] = useState([]);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const formUser = useRef();

    const handleFormSubmit = async (values) => {
        setLoading((prev) => !prev)
    const result =    await service.post(routes.novoUsuario, values);
        setLoading((prev) => !prev)
        onClose();
    };

    const getCursos = async () => {
        const cursos = await service.get(routes.curso);
        if (cursos.code === 'ERR_BAD_REQUEST') {
            setCursos([]);
        } else {
            setCursos(cursos);
        }
    };

    const handleClose = () => {
        setCursos([])
        onClose();
    };
    const checkoutSchema = yup.object().shape({
        nome: yup.string().required("Obrigatório"),
        email: yup.string().email("Email inválido").required("Obrigatório"),
        idProduto: yup.string()
            .test('is-required', 'Selecione um curso válido', (value) => value !== '0')
            .required('Obrigatório'),
    });
    const initialValues = {
        nome: "",
        email: "",
        idProduto: '0',
    };


    useEffect(() => {
        if(onOpen)
        getCursos();
    }, [onOpen]);
    const handleButtonClick = () => {
        formUser.current.handleSubmit();
    };
    return (
        <div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Novo Usuário
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                <LoadingSpinner loading={loading} />
                    <Formik
                        innerRef={formUser}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.nome}
                                        name="nome"
                                        error={!!touched.nome && !!errors.nome}
                                        helperText={touched.nome && errors.nome}
                                        sx={{ gridColumn: "span 12" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 12" }}
                                    />
                                    <Field
                                        as={Select}
                                        fullWidth
                                        variant="filled"
                                        label="Selecione uma opção"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.idProduto}
                                        name="idProduto"
                                        error={!!touched.idProduto && !!errors.idProduto}
                                        helperText={touched.idProduto && errors.idProduto}
                                        sx={{ gridColumn: 'span 12' }}
                                    >
                                        <MenuItem value="0">Selecione um curso</MenuItem>
                                        {cursos.map((curso) => (
                                            <MenuItem key={curso.idProduto} value={curso.idProduto}>
                                                {curso.nomeCurso}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleClose}   variant="contained" >
                        Fechar
                    </Button>
                    <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        color="primary"
                        sx={{ color: colors.appColor['branca'], backgroundColor:colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default NovoUser;
