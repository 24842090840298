import { useState, useRef, useEffect } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, TextField, Box, FormControlLabel, Checkbox, } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { parceiroController } from "../../../core/controllers/parceiroController";
import CustomSnackbar from "../../../components/Snackbar";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const Parceiro = ({ onOpen, onClose, dados, titulo }) => {
    const [loading, setLoading] = useState(false)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [checked, setChecked] = useState(true);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const formUser = useRef();
    const [acao, setAcao] = useState('NOVO');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info', duration: 6000 }); //success, error, warning, info

    const handleFormSubmit = async (values) => {
        setLoading((prev) => !prev);
        const body = {
            nome: values.nome,
            chaveHottok: values.chave,
            ativo: checked,
            prefixoUserId: values.prefixo
        }
        try {
            const result = (dados.acao === 'EDITAR') ? await parceiroController.atualizarParceiro(dados.id, body) : await parceiroController.novoParceiro(body);

            if (result.status) {
                setSnackbar({ open: true, message: result.mensagem, severity: 'success', duration: 600 });

            } else {
                setSnackbar({ open: true, message: result.mensagem, severity: 'error' });
            }
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }
        setLoading((prev) => !prev)

    };

    const handleClose = () => {
        onClose();
    };

    let checkoutSchema = yup.object().shape({
        nome: yup.string().required("Obrigatório"),
        chave: yup.string().when(['acao'], (dados, schema) => {
            if (acao && acao === 'EDITAR') {
                return schema.nullable();
            } else {
                return schema.required("Obrigatório");
            }
        }),
        prefixo: yup.string().required("Obrigatório"),
    });

    const initialValues = {
        nome: dados.nome || "",
        chave: "",
        prefixo: dados.prefixoUserId || "",
    };

    const handleChangeActive = (event) => {
        setChecked(event.target.checked);
    };
    const handleButtonClick = () => {
        formUser.current.handleSubmit();
    };
    useEffect(() => {
        setAcao(dados.acao);
        setChecked(dados.ativo !== undefined ? dados.ativo : true);
    }, [dados]);


    return (
        <div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {titulo}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <CustomSnackbar
                        open={snackbar.open}
                        handleClose={() => {
                            setSnackbar({ open: false });
                            if (snackbar.severity === 'success') {
                                onClose();
                            }

                        }}
                        message={snackbar.message}
                        severity={snackbar.severity}
                        duration={snackbar.duration}
                    />
                    <LoadingSpinner loading={loading} />
                    <Formik
                        innerRef={formUser}
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                    sx={{
                                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.nome}
                                        name="nome"
                                        error={!!touched.nome && !!errors.nome}
                                        helperText={touched.nome && errors.nome}
                                        sx={{ gridColumn: "span 12" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Chave Autenticação Hotmart"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.chave}
                                        name="chave"
                                        error={!!touched.chave && !!errors.chave}
                                        helperText={touched.chave && errors.chave}
                                        sx={{ gridColumn: "span 12" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Prefixo de identificação D2L "
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.prefixo}
                                        name="prefixo"
                                        error={!!touched.prefixo && !!errors.prefixo}
                                        helperText={touched.prefixo && errors.prefixo}
                                        sx={{ gridColumn: "span 12" }}
                                    />
                                    <FormControlLabel
                                        label="Ativo"
                                        control={<Checkbox checked={checked} onChange={handleChangeActive} />}
                                    />
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleClose} variant="contained" >
                        Fechar
                    </Button>
                    <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        color="primary"
                        sx={{ color: colors.appColor['branca'], backgroundColor: colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default Parceiro;
