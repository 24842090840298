import React, { useState } from 'react';
import { Button, TextField, Paper, Typography, Container, useTheme, Box } from '@mui/material';
import { tokens } from "../../theme";
import { loginController } from '../../core/controllers/loginController';
import CustomSnackbar from '../../components/Snackbar';
import { cookies } from '../../core/cookies';

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = cookies.getCookie('secureSessionVal');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  if (token) {
    window.location.assign('/dashboard')
  }
  const [errorState, setErrorState] = useState({
    error: false,
    message: '',
  })
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    setLoading(true)
    setErrorState({
      error: false,
      message: '',
    })
    cookies.deleteAllCookies();
    if (username !== '' && password !== '') {
      try {
        const body = { usuario: username, senha: password };
        await loginController.login(body);

      } catch (error) {
        if (error.response.data.logado === false) {
          setErrorState({
            error: true,
            message: 'Email e/ou senha inválidos.',
          })
        } else
          handleSnackbar(error.message, 'error');
      }
      setLoading(false)
    
    } else {
      setErrorState({
        error: true,
        message: 'Todos os campos são necessário.',
      })
      setLoading(false)
    }
  };
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  }
  const handleSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message: message,
      severity: severity
    });
  };

  return (

    <Container maxWidth="xs" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box
        m="20px 0 0 0"
        height="2vh"
        sx={{
          '& .testessss': {
            '-webkit-box-shadow': '0 0 0 100px #704292 inset !important'
          }

        }}
      ></Box>
      <Paper elevation={3} style={{ padding: '16px', width: '100%', backgroundColor: colors.appColor['primary'] }}>
        <Typography variant="h5" component="h1" align="center">
          Login
        </Typography>
        <CustomSnackbar
          open={snackbar.open}
          handleClose={() => {
            setSnackbar({ ...snackbar, open: false });//{ open: false, message: '', severity: '' });
          }}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <form style={{ marginTop: '16px', width: '100%' }}>
          <TextField
            label="Usuário"
            fullWidth
            margin="normal"
            variant="outlined"
            inputProps={{ className: 'testessss' }}
            sx={{ boxShadow: '0 0 0 100px #704292 inset' }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Senha"
            fullWidth
            margin="normal"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={keyPress}
          />
          {errorState.error && <span style={{ color: colors.appColor['vermelho'] }}>{errorState.message}</span>}
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleLogin}
            style={{ marginTop: '16px' }}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
