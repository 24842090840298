import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';

const Columns = ({funcao}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleOpenModalCurso = (dados) => {
        funcao(dados)
    };

    

    const columns = [
        {
            field: "nome",
            headerName: "Nome",
            minWidth: 200,
            flex: 1,
        },
        {
            field: "prefixoUserId",
            headerName: "Prefixo de Usuário",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 260,
            //  flex: 1, 
        },
        {
            field: "ativo",
            headerName: "Ativo",
            minWidth: 20,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row: { ativo } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        color={
                            ativo
                                ? colors.appColor['verde']
                                : colors.appColor['vermelho']
                        }
                        borderRadius="4px"
                    >
                        {ativo ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                        
                    </Box>
                );
            },
        },
        {
            field: "chaveHottok",
            headerName: "Autenticação HotMart",
            minWidth: 160,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "acao",
            headerName: "Ação",
            sortable: false,
            minWidth: 20,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); 

                    const dados = {
                        nome: params.row.nome,
                        ativo: params.row.ativo,
                        id: params.row.id,
                        prefixoUserId: params.row.prefixoUserId,
                        acao: 'EDITAR'
                    }

                    return handleOpenModalCurso(dados);
                };

                return <IconButton
                    aria-label="search" 
                    color="inherit"
                    onClick={onClick}
                    >
                    <EditIcon />
                </IconButton>
            }
        },
    ];

    return columns;
}
Columns.prototype={
    funcao: PropTypes.func.isRequired,
}
export default Columns;