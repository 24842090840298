import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

const Columns = ({funcao}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleOpenModalCurso = (dados) => {
        funcao(dados)
    };

    

    const columns = [
        // { field: "idUsuario", headerName: "ID" },
        {
            field: "nomeSobrenome",
            headerName: "Nome",
            minWidth: 200,
            flex: 1,
            cellClassName: "name-column--cellssss",
            valueGetter: (params) => {
                return params.row.nome + " " + params.row.sobrenome;
            },
        },
        {
            field: "email",
            headerName: "Email",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 260,
            //  flex: 1, 
        },
        {
            field: "ativo",
            headerName: "Ativo",
            minWidth: 20,
            headerAlign: "center",
            align: "center",
            renderCell: ({ row: { ativo } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        color={
                            ativo
                                ? colors.appColor['verde']
                                : colors.appColor['vermelho']
                        }
                        borderRadius="4px"
                    >
                        {ativo ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                        
                    </Box>
                );
            },
        },
        {
            field: "dataCriacao",
            headerName: "Data de Criação",
            minWidth: 160,
            valueFormatter: params =>
                moment(params?.value).format("DD/MM/YYYY HH:mm"),
        },
        {
            field: "sincronizado",
            headerName: "Sincronizado",
            minWidth: 20,
            renderCell: ({ row: { sincronizado } }) => {
                return (
                    <Box
                        width="60%"
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        color={
                            sincronizado
                                ? colors.appColor['verde']
                                : colors.appColor['vermelho']
                        }
                        borderRadius="4px"
                    >
                        {sincronizado ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                    </Box>
                );
            },
        },
        {
            field: "cursoHotmart",
            headerName: "Curso Hotmart",
            sortable: false,
            minWidth: 20,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const dados = {
                        nome: params.row.nome + " " + params.row.sobrenome,
                        email: params.row.email,
                        id: params.row.id,
                        acao: 'cursoHotmart'
                    }

                    return handleOpenModalCurso(dados);
                };

                return <IconButton
                    aria-label="search" 
                    color="inherit"
                    onClick={onClick}
                    >
                    <SearchIcon />
                </IconButton>
            }
        },
        {
            field: "cursoD2L",
            headerName: "Curso D2L",
            sortable: false,
            minWidth: 20,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    const dados = {
                        nome: params.row.nome + " " + params.row.sobrenome,
                        email: params.row.email,
                        id: params.row.id,
                        acao: 'cursoD2L'
                    }

                    return handleOpenModalCurso(dados);
                };

                return <IconButton
                    aria-label="search" 
                    color="inherit"
                    onClick={onClick}
                    >
                    <SearchIcon />
                </IconButton>
            }
        },
    ];

    return columns;
}
Columns.prototype={
    funcao: PropTypes.func.isRequired,
}
export default Columns;