import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./scenes/login"
import Sidebar from "./scenes/global/Sidebar";
import Users from "./scenes/users";
import Courses from "./scenes/courses";
import Settings from "./scenes/settings";
import Partners from "./scenes/partners";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import ProtectedRoute from './auth'
import { cookies } from "./core/cookies";
import Dashboard from "./scenes/dashboard";



function App() {
  
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const user = cookies.getCookie('secureSessionVal');
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
         {user && <Sidebar isSidebar={isSidebar} /> }
          <main className="content">
         
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/usuarios" element={<ProtectedRoute link={<Users/>}/>} />
              <Route path="/courses" element={<ProtectedRoute link={<Courses/>}/>} />
              <Route path="/settings" element={<ProtectedRoute link={<Settings/>}/>} />
              <Route path="/partners" element={<ProtectedRoute link={<Partners/>}/>} />
              <Route path="/dashboard" element={<ProtectedRoute link={<Dashboard/>}/>} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
