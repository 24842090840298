import React from 'react';
import PropTypes from 'prop-types';
import Login from "./scenes/login";
import { cookies } from './core/cookies';

const ProtectedRoute = ({ link }) => {
  const user = cookies.getCookie('secureSessionVal');
  return (
    !user ? <Login /> : link
  );
}

ProtectedRoute.propTypes = {
    link: PropTypes.node.isRequired,
};

export default ProtectedRoute;
