import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { tokens } from "../theme";

const ColorButton = styled(Button)(({ theme, minheight }) => {
    const colors = tokens(theme.palette.mode);
    return {
        color: colors.appColor['branca'],
        backgroundColor: colors.appColor['primary'],
        minHeight:(minheight)?minheight:54,
        '&:hover': {
            backgroundColor: colors.appColor['secondary'],
        },
    };
});

export default ColorButton;