import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { service } from '../../core/services';
import Header from "../../components/Header";
import StyleGrid from "../../components/grid/StyleGrid";
import CustomNoRowsOverlay from "../../components/grid/CustomNoRowsOverlay";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Columns from "./columns";
import StyledMenu from '../../components/popMenu/StyledMenu';
import ModalCursoD2L from './modal/modalCursoD2L';
import ModalCursoHotMart from './modal/modalCursoHotMart';
import NovoUser from './modal/novoUser';
import ModalConfirm from '../../components/ModalConfirm';
import Constants from '../../constants';
import routes from '../../core/services/routes';
import LoadingSpinner from '../../components/LoadingSpinner';
import NovaSenha from './modal/novaSenha';
import CustomSnackbar from '../../components/Snackbar';
import InscreverUsuario from './modal/inscreverUsuario';

const Users = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false)
    const [usuarios, setUsuarios] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dadosUser, setDadosUser] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [openModalCursoD2L, setOpenModalCursoD2L] = useState(false);
    const [openModalCursoHotmart, setOpenModalCursoHotmart] = useState(false);
    const [msgConfirm, setMsgConfirm] = useState('');
    const [acao, setAcao] = useState('');
    const [openModal, setOpenModal] = useState({ modalConfirm: false, modalNewUser: false, modalNovaSenha: false, inscreverUser: false });
    const [rowsSelected, setRowsSelected] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' }); //success, error, warning, info


    const handleSnackbar = (message, severity) => {
        setSnackbar({
            open: true,
            message: message,
            severity: severity
        });
    };
    const getUsuarios = async () => {
        setLoading((prev) => !prev);
        try {
            let query = '';
            if (searchTerm !== '') {
                query = `?nome=${searchTerm}`;
            }
            const users = await service.get(`usuarios${query}`);
            if (users) {
                if (users.code === 'ERR_BAD_REQUEST') {
                    setUsuarios([]);
                } else {
                    setUsuarios(users);
                }
            }
        } catch (error) {
            handleSnackbar(error.message, 'error');
        }
        setLoading((prev) => !prev);
    };


    const keyPress = (e) => {
        if (e.keyCode === 13) {
            getUsuarios();
        }
    }
    const ColorButton = styled(Button)(({ theme }) => ({
        color: colors.appColor['branca'],
        backgroundColor: colors.appColor['primary'],
        minHeight: 54,
        '&:hover': {
            backgroundColor: colors.appColor['secondary'],
        },
    }));
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        getUsuarios();
    }, []);

    const handleOpenModalCurso = (dados) => {
        setDadosUser(dados);

        switch (dados.acao) {
            case 'cursoHotmart':
                setOpenModalCursoHotmart(true);
                break;
            case 'cursoD2L':
                setOpenModalCursoD2L(true);
                break;
            default:
                break;
        }


    };
    const handleCloseModal = () => {
        setOpenModalCursoD2L(false);
        setOpenModalCursoHotmart(false);
        setOpenModal({ modalConfirm: false, modalNewUser: false, modalNovaSenha: false, inscreverUser: false });
        getUsuarios();
    };

    const handleConfirmar = async (dados) => {
        setLoading((prev) => !prev);
        let body = '';
        let result = '';
        switch (acao) {
            case 'INATIVAR':
                body = {
                    usuarios: rowsSelected.map(id => ({ idUsuario: id }))
                };
                result = await service.put(routes.inativarUsuario, body);
                break;
            case 'SENHA_EMAIL':
                body = {
                    senha: '',
                    usuarios: rowsSelected.map(id => ({ idUsuario: id }))
                };
                result = await service.put(routes.alterarSenhaPorEmail, body);
                break;
            case 'SENHA_MANUAL':
                body = {
                    senha: dados.senha,
                    usuarios: rowsSelected.map(id => ({ idUsuario: id }))
                };
                result = await service.put(routes.alterarSenhaManual, body);
                break;
            case 'INSCREVER':
                body = {
                    idProduto: dados.idProduto,
                    usuarios: rowsSelected.map(id => ({ idUsuario: id }))
                };
                result = await service.post(routes.inscreverUsuario, body);
                break;
            default:
                break;
        }

        if (result.code !== 'ERR_BAD_REQUEST' || result.code !== 'ERR_BAD_RESPONSE') {
            if (result.status) {
                setSnackbar({ open: true, message: result.mensagem, severity: 'success' });
            } else {
                setSnackbar({ open: true, message: result.mensagem, severity: 'error' });
            }
        } else {
            setSnackbar({ open: true, message: 'Algo deu errado, tente novamente.', severity: 'error' });
        }

        setLoading((prev) => !prev);
        //Atualiza os usuários.
        getUsuarios();
    }

    const columns = Columns({ funcao: handleOpenModalCurso });
    return (
        <Box m="15px">
            <Header title="Usuários" subtitle="Controle de usuários" />
            <LoadingSpinner loading={loading} />
            <ModalCursoD2L onOpen={openModalCursoD2L} dados={dadosUser} onClose={handleCloseModal}></ModalCursoD2L>
            <ModalCursoHotMart onOpen={openModalCursoHotmart} dados={dadosUser} onClose={handleCloseModal}></ModalCursoHotMart>
            <NovoUser onOpen={openModal.modalNewUser} onClose={handleCloseModal}></NovoUser>
            <NovaSenha onOpen={openModal.modalNovaSenha} onClose={handleCloseModal} dados={handleConfirmar} />
            <InscreverUsuario onOpen={openModal.inscreverUser} onClose={handleCloseModal} dados={handleConfirmar} />
            <CustomSnackbar
                open={snackbar.open}
                handleClose={() => {
                    setSnackbar({ ...snackbar, open: false });//{ open: false, message: '', severity: '' });
                }}
                message={snackbar.message}
                severity={snackbar.severity}
            />
            <ModalConfirm
                onOpen={openModal.modalConfirm}
                onClose={handleCloseModal}
                onConfirm={handleConfirmar}
                msg={(rowsSelected.length > 0) ? msgConfirm : Constants.erroSemUsuarioSelecionado}
                onTipo={(rowsSelected.length > 0) ? 'C' : 'A'}
            ></ModalConfirm>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper
                    component="div"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: colors.appColor['secondary'] }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Pesqusiar..."
                        inputProps={{ 'aria-label': 'Pesqusiar...' }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={keyPress}
                    />
                    <IconButton type="button" sx={{ p: '10px' }}
                        aria-label="search"
                        onClick={getUsuarios}
                    >
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Box>
            <Box
                m="10px 0 0 0"
                height="75vh"
                sx={StyleGrid}
            >
                <Box
                    m="0 0 5px 0"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                        border: (theme) => `none`,
                        borderRadius: 1,
                        bgcolor: colors.appColor['primary'],
                        color: colors.appColor['secondary'],
                        '& svg': {
                            m: 1.5,
                        },
                        '& hr': {
                            mx: 0.5,
                        },
                    }}
                >
                    <ColorButton
                        onClick={() => {
                            setOpenModal({ modalNewUser: true });
                        }}
                    >Novo Usuário</ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={() => {
                            setAcao('INATIVAR');
                            setMsgConfirm(Constants.confirmInativarUsuarios);
                            setOpenModal({ modalConfirm: true });
                        }}
                    >Inativar Usuários</ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={() => {
                            if (rowsSelected.length > 0) {
                                setAcao('INSCREVER');
                                setOpenModal({ inscreverUser: true });
                            } else {
                                setMsgConfirm('');
                                setOpenModal({ modalConfirm: true });
                            }
                        }}
                    >Inscrever</ColorButton>
                    <Divider orientation="vertical" flexItem />
                    <ColorButton
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}>Redefinir Senha</ColorButton>
                    <StyledMenu
                        id="customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'customized-button', 'top': '238px',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem disableRipple
                            onClick={() => {
                                setAcao('SENHA_EMAIL');
                                handleClose();
                                setMsgConfirm(Constants.confirmEnviarNovaSenha);
                                setOpenModal({ modalConfirm: true });
                            }}
                        >
                            Redefinir por Email
                        </MenuItem>
                        <MenuItem disableRipple
                            onClick={() => {
                                handleClose();
                                if (rowsSelected.length > 0) {
                                    setAcao('SENHA_MANUAL');
                                    setOpenModal({ modalNovaSenha: true });
                                } else {
                                    setMsgConfirm('');
                                    setOpenModal({ modalConfirm: true });
                                }

                            }}
                        >
                            Redefinir Manualmente
                        </MenuItem>
                    </StyledMenu>

                </Box>
                <DataGrid checkboxSelection
                    rows={usuarios}
                    columns={columns} 
                    loading={loading}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowsSelected(newRowSelectionModel);
                    }}
                    slots={{
                     noRowsOverlay: CustomNoRowsOverlay,
                    }}
                />
            </Box>
        </Box>
    );
};

export default Users;
