import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import StyleGrid from "../../../components/grid/StyleGrid";
import { service } from "../../../core/services/index";
import CustomNoRowsOverlay from "../../../components/grid/CustomNoRowsOverlay";
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import ColorButton from "../../../components/ColorButton";
import ModalConfirm from "../../../components/ModalConfirm";
import Constants from "../../../constants";
import routes from "../../../core/services/routes";
import ModalProrrogarAcesso from "../../../components/modalProrrogarAcesso";
import LoadingSpinner from "../../../components/LoadingSpinner";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Users = ({ onOpen, onClose, dados }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false)
    const [inscricoes, setInscricoes] = useState([]);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [openModal, setOpenModal] = useState({ modalConfirm: false, modalProrragar: false });
    const [msgConfirm, setMsgConfirm] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);


    const getInscricoes = async () => {
        setLoading(true)
        let query = `?IdCurso=${dados.idCurso}`;
        const insc = await service.get(`cursos/buscar-usuarios${query}`);
        if (insc.code !== 'ERR_BAD_REQUEST' && insc.dados) {

            const rows = insc.dados;
            setInscricoes(rows)

        } else {
            setInscricoes([])
        }
        setLoading(false);
    };

    const handleClose = () => {
        setInscricoes([])
        onClose();
    };
    const columns = [
        {
            field: "nome",
            headerName: "Nome",
            minWidth: 300,
            cellClassName: "name-column--cell",
            valueGetter: (params) => {
                return params.row.nome + " " + params.row.sobrenome;
            },
        },
        {
            field: "ativo",
            headerName: "Status",
            minWidth: 2,
            renderCell: (params) => {

                return (
                    <Box sx={{ alignItems: "revert-layer" }}>
                        {params.row.ativo ? <CheckCircleOutline color="success" /> : <HighlightOff color="error" />}
                    </Box>
                );
            }
        },

    ];
    const handleCloseModal = () => {
        setOpenModal({ modalConfirm: false, modalProrragar: false });
    };
    const handleProrrogaAcesso = async (dias) => {
        setLoadingSpinner((prev) => !prev);
        const body = {
            idCurso: dados.idCurso,
            diasAcesso: dias,
            usuariosProrrogar: rowsSelected.map(id => ({ idUsuario: id }))
        };
        await service.put(routes.prorrogarAcesso, body);
        setLoadingSpinner((prev) => !prev);
        getInscricoes();
    }
    const handleConfirmar = async () => {
        setLoadingSpinner((prev) => !prev);
        const body = {
            IdCurso: dados.idCurso,
            desmatricular: rowsSelected.map(id => ({ idUsuario: id }))
        };
        await service.put(routes.desmatricularUsuarioCurso, body);
        setLoadingSpinner((prev) => !prev);
        getInscricoes();
    }
    useEffect(() => {
        if (dados.idCurso && dados.acao === "visualizar")
            getInscricoes();
    }, [dados]);

    return (
        <div>

            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: colors.appColor['primary'],
                    }
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Incrições no Curso
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <ModalConfirm
                        onOpen={openModal.modalConfirm}
                        onClose={handleCloseModal}
                        onConfirm={handleConfirmar}
                        msg={(rowsSelected.length > 0) ? msgConfirm : Constants.erroSemUsuarioSelecionado}
                        onTipo={(rowsSelected.length > 0) ? 'C' : 'A'}
                    ></ModalConfirm>
                    <ModalProrrogarAcesso onOpen={openModal.modalProrragar} onClose={handleCloseModal} onGetDias={handleProrrogaAcesso} />
                    <LoadingSpinner loading={loadingSpinner} />
                    <Typography
                        sx={{ marginBottom: "5px" }}
                    >Curso: {dados.nomeCurso}
                    </Typography>
                    <Box
                        m="0 0 5px 0"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 'fit-content',
                            border: () => `none`,
                            borderRadius: 1,
                            bgcolor: colors.appColor['primary'],
                            color: colors.appColor['secondary'],
                            '& svg': {
                                m: 1.5,
                            },
                            '& hr': {
                                mx: 0.5,
                            },
                        }}
                    >
                        <ColorButton minheight={20}
                            onClick={() => {
                                if (rowsSelected.length > 0) {
                                    setOpenModal({ modalProrragar: true });
                                } else {
                                    setMsgConfirm('');
                                    setOpenModal({ modalConfirm: true });
                                }
                            }} >
                            Prorrogar Acesso</ColorButton>
                        <Divider orientation="vertical" flexItem />
                        <ColorButton minheight={20}
                            onClick={() => {
                                setMsgConfirm(Constants.confirmInativarUsuarios);
                                setOpenModal({ modalConfirm: true });
                            }} >
                            Desmatricular Usuários
                        </ColorButton>
                    </Box>
                    <Box
                        height="50vh"

                        sx={StyleGrid}
                    >
                        <DataGrid rows={inscricoes} columns={columns}
                            checkboxSelection
                            loading={loading}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowsSelected(newRowSelectionModel);
                            }}
                            selectionModel={rowsSelected}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} sx={{ color: colors.appColor['branca'] }}>
                        Fechar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default Users;
