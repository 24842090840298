import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, useTheme, TextField } from "@mui/material";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Planilha from './planilha';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Importe o locale do Brasil






import { tokens } from "../../theme";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { service } from "../../core/services";
import { courseController } from "../../core/controllers/coursesController";
import LineChart from "../../components/LineChart";
/*import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";

import ProgressCircle from "../../components/ProgressCircle";*/

const Dashboard = () => {
  dayjs.locale('pt-br'); // Configurar o locale para 'pt-br'
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [parceiros, setParceiros] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [cursosFiltrados, setCursosFiltrados] = useState([]);
  const [parceiroSelected, setParceiroSelected] = useState('');
  const [cursoSelected, setCurso] = useState('0');
  const [dadosDash, setDadosDash] = useState({ inscricoes: 0, percInscricoes: 0.0, saidas: 0, percSaidas: 0.0, ativos: 0, percAtivos: 0.0, inativos: 0, percInativos: 0.0 });
  const [incricoesDia, setIncricoesDia] = useState([]);
  const [inscRecentes, setInscRecentes] = useState([]);
  const [usuariosCursos, setUsuariosCursos] = useState([]);

const  lineColors = ['#4cceac', '#a4a9fc', '#0e9772', '#006fbf', '#58201e', '#1F2A40'];

  const handleChangeParceiro = (event) => {
    setParceiroSelected(event.target.value);
    if (event.target.value === '0') {
      getCursos();
    } else {
      const filtro = cursos.filter(curso => curso.idParceiro.toString() === event.target.value);
      setCursosFiltrados(filtro);
    }

  };
  const handleChangeCurso = (event) => {
    setCurso(event.target.value);
  };
  const getParceiros = async () => {
    const par = await service.get(`parceiros`);

    if (par.code === 'ERR_BAD_REQUEST') {
      setParceiros([]);
    } else {
      setParceiros(par);
    }
  };
  const getCursos = async () => {
    try {
      let query = '';

      const cursos = await courseController.getCursos(query);
      if (cursos.code === 'ERR_BAD_REQUEST') {
        setCursos([]);
        setCursosFiltrados([]);
      } else {
        setCursos(cursos);
        setCursosFiltrados(cursos);
      }
    } catch (error) {
      console.error(error);
    }

  };

  const [dataInicio, setdataInicio] = useState(dayjs(new Date()).subtract(30, 'days'));
  const [dataFim, setDataFim] = useState(dayjs(new Date()));


  const handleDataInicio = (data) => {
    setdataInicio(data);
  //  console.log(dayjs(data).format('DD/MM/YYYY'));
   
  }
  const handleDataFim = (data) => {
    setDataFim(data);
  }
  const handleFiltrar = async () => {
    try {
     
      let idsCursos;
      if(cursosFiltrados.length  > 0){
        idsCursos = cursosFiltrados.map(dado => dado.id).join(',');
      }else{
        idsCursos = cursoSelected;
      }
    
      let query = '?idsCursos=' + idsCursos + '&inicio=' + dayjs(dataInicio).format('YYYY-MM-DD') + '&fim=' + dayjs(dataFim).format('YYYY-MM-DD');
     
      const usuarios = await courseController.getUsuarios(query);
      if (usuarios.code === 'ERR_BAD_REQUEST') {
        console.log('ERR_BAD_REQUEST');

      } else {
        setUsuariosCursos(usuarios.dados);
        // Calculando a quantidade de usuários ativos e inativos
        const totalUsers = usuarios.dados.length;
        const activeUsers = usuarios.dados.filter(user => user.ativo).length;
        const inactiveUsers = totalUsers - activeUsers;

        // Calculando percentuais
        const activePercentage = activeUsers / totalUsers;
        const inactivePercentage = inactiveUsers / totalUsers;
        setDadosDash({ inscricoes: totalUsers, percInscricoes: activePercentage, saidas: inactiveUsers, percSaidas: inactivePercentage, ativos: activeUsers, percAtivos: activePercentage, inativos: inactiveUsers, percInativos: inactivePercentage });
        // Pegar os 5 últimos registros de cada curso
        const ultimosRegistros = {};
        Object.keys(usuarios).forEach(curso => {
          ultimosRegistros[curso] = usuarios[curso].slice(-5);
        });
        setInscRecentes(ultimosRegistros.dados);
        // Agrupar os dados pelo campo "curso"
        const grupos = {};
        usuarios.dados.forEach(dado => {
          if (!grupos[dado.nomeCurso]) {
            grupos[dado.nomeCurso] = [];
          }
          grupos[dado.nomeCurso].push(dado);
        });
        // Estruturar os dados no formato desejado
        const dataFormatada = Object.keys(grupos).map(nomeCurso => {
          const color = lineColors[Math.floor(Math.random() * lineColors.length)]; 
          const data = {};
          grupos[nomeCurso].forEach(dado => {
            const dataInicio = new Date(dado.dataInicio);
            const formattedDate = `${dataInicio.getDate()}/${dataInicio.getMonth() + 1}/${dataInicio.getFullYear()}`;
            if (!data[formattedDate]) {
              data[formattedDate] = 0;
            }
            data[formattedDate]++;
          });
          const formattedData = Object.entries(data).map(([date, count]) => ({
            x: date,
            y: count
          }));
          return {
            id: nomeCurso,
            color: color,
            data: formattedData
          };
        });

        setIncricoesDia(dataFormatada);
       
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    //const novaDataInicio = dayjs(new Date()).subtract(30, 'days');
    // setdataInicio(novaDataInicio);
    setParceiroSelected('0');
    getParceiros();
    setCurso('0');
    getCursos();
    handleFiltrar();
  }, []);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Dashboard de usuário" />

        <Box>
        <Planilha key={Date.now()} dados={usuariosCursos} />
         
        </Box>
      </Box>
      {/* FILTROS */}


      <Box
        display="grid"
        backgroundColor={colors.appColor['secondary']}
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="80px"
        gap="20px"
      >

        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ marginLeft: 3 }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Parceiro</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={parceiroSelected}
              label="Parceiro"
              onChange={handleChangeParceiro}
            >
              <MenuItem value="0">Todos parceiros</MenuItem>
              {parceiros.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Curso</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={cursoSelected}
              label="Curso"
              onChange={handleChangeCurso}
            >
              <MenuItem value="0">Todos cursos</MenuItem>
              {cursosFiltrados.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.nomeCurso}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          gridColumn="span 4"
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Data Início"
              format="DD/MM/YYYY"
              value={dataInicio}
              onChange={handleDataInicio}
              renderInput={(props) => <TextField {...props} variant="outlined" />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label="Data Fim"
              format="DD/MM/YYYY"
              value={dataFim}
              onChange={handleDataFim}
              renderInput={(props) => <TextField {...props} variant="outlined" />}
            />
          </LocalizationProvider>
        </Box>


      </Box>
      <Box
        gridColumn="span 4"
        backgroundColor={colors.appColor['secondary']}
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{ marginBottom: 5, height: 50 }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: 2, fontSize: "16px", minWidth: 120 }}
          onClick={handleFiltrar}  >

          Filtrar
        </Button>
      </Box>
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.appColor['secondary']}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dadosDash.inscricoes}
            subtitle="Inscrições"
            progress="1,0"

            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.appColor['secondary']}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dadosDash.inativos}
            subtitle="Saídas"
            progress={dadosDash.percSaidas}
            increase={"-" + (dadosDash.percSaidas * 100).toFixed(2) + "%"}
            icon={
              <PersonRemoveIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.appColor['secondary']}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dadosDash.ativos}
            subtitle="Ativos"
            progress={dadosDash.percAtivos}
            increase={(dadosDash.percAtivos * 100).toFixed(2) + "%"}
            icon={
              <PersonIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.appColor['secondary']}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={dadosDash.inativos}
            subtitle="Inativos"
            progress={dadosDash.percInativos}
            increase={(dadosDash.percInativos * 100).toFixed(2) + "%"}
            icon={
              <PersonOffIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.appColor['secondary']}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Inscrições por dia
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            {<LineChart isDashboard={true} dados={incricoesDia} />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.appColor['secondary']}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Inscrições Recentes
            </Typography>
          </Box>
          {inscRecentes.map((transaction, i) => (
            <Box
              key={`${transaction.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {transaction.nome + ' ' + transaction.sobrenome}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.email}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{dayjs(transaction.dataInicio).format('DD/MM/YYYY')}</Box>
              
            </Box>
          ))}
        </Box>


      </Box>
    </Box>
  );
};

export default Dashboard;
