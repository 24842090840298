export const cookies = {
    // Definindo um cookie com um nome, valor e opções (expiração, SameSite, Secure, HttpOnly)
    setCookie: (name, value, expires) => {
        // Configurando a expiração (em dias)
      //  const expirationDays = expires || 1; // Padrão: 1 dia
    //    const expirationDate = new Date();
    //    expirationDate.setTime(expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000);

    //    let cookieString = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}`;

        // Adicionando SameSite, Secure e HttpOnly se fornecidos nas opções

      //  cookieString += `; SameSite=Strict`;
       // cookieString += '; Secure';
        //  cookieString += '; HttpOnly';

        // Definindo o cookie no documento
     //   document.cookie = cookieString;
       // opçaõ document.cookie = `${name}=${encodeURIComponent(value)}; path=/;`; // Definindo o caminho para o cookie
       sessionStorage.setItem(name, value)
    },
    getCookie: (name) => {
      /*  const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return decodeURIComponent(cookieValue);
            }
        }
        return null; // Retorna null se o cookie não for encontrado*/
        return sessionStorage.getItem(name)
    
    },
    deleteAllCookies: () => {
       /* const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;'; // Definindo uma data de expiração passada
        }*/
        sessionStorage.clear();
    }
}