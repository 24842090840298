import PropTypes from 'prop-types';
import { Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
const Columns = ({ funcao }) => {
    const handleOpenModalUsuarios = (dados) => {
        funcao(dados)
    };
    const columns = [
        {
            field: "nomeCurso",
            headerName: "Nome",
            minWidth: 200,
            flex: 1,
            cellClassName: "name-column--cellssss",
        },
        {
            field: "duracaoDias",
            headerName: "Duração em Dias",
            type: "number",
            headerAlign: "left",
            align: "left",
            minWidth: 120,
            //  flex: 1, 
        },
        {
            field: "situacao",
            headerName: "Status",
            minWidth: 2,
            renderCell: (params) => {

                return (
                    <Box sx={{ alignItems: 'revert-layer' }}>
                        {params.row.ativo ? <CheckCircleOutline color="success" /> : <HighlightOff color="error" />}
                    </Box>
                );
            }

        },
        {
            field: "usurios",
            headerName: "Usuários",
            minWidth: 2,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation();

                    const par = {
                        idCurso: params.row.id,
                        nomeCurso: params.row.nomeCurso,
                        acao: "visualizar"
                    }
                    return handleOpenModalUsuarios(par)
                };

                return (
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={onClick}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Box>
                );
            }

        }
        ,
        {
            field: "acoes",
            headerName: "Ação",
            minWidth: 2,
            renderCell: (params) => {
                const onClick = (e) => {
                    e.stopPropagation();

                    const par = {
                        idCurso: params.row.id,
                        nomeCurso: params.row.nomeCurso,
                        acao: "editar"
                    }
                    return handleOpenModalUsuarios(par)
                };

                return (
                    <Box>
                        <IconButton
                            color="inherit"
                            onClick={onClick}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                );
            }

        }
    ];

    return columns;
}
Columns.prototype = {
    funcao: PropTypes.func.isRequired,
}
export default Columns;