import React, {useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, Box, InputBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from "@mui/x-data-grid";
import Paper from '@mui/material/Paper';
import { tokens } from "../../../theme";
import { service } from "../../../core/services/index";
import StyleGrid from "../../../components/grid/StyleGrid";
import CustomNoRowsOverlay from "../../../components/grid/CustomNoRowsOverlay";
import Columns from "./columns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const ModalCursoD2L = React.memo(({ onOpen, onClose, dados, addListOrgs }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [searchTerm, setSearchTerm] = useState('');
    const [listOrgs, setListOrgs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const handClose = () => {
        setListOrgs([]);
        onClose();
    }
    const getUnidadesOrganizacional = async () => {
        if (searchTerm !== '') {
            let query = `?OrgUnitName=${searchTerm}`; 

            const orgs = await service.get(`d2l/unidade-organizacional${query}`);
            if (orgs.code !== 'ERR_BAD_REQUEST') {
                setListOrgs(orgs);
            }
        }

    };
    const handleAdicionar = () => {
        const rowsStrings = rows.map(id => id.toString());

        const registrosFiltrados = listOrgs.filter((item) =>
            rowsStrings.includes(item.id)
        );
        addListOrgs(registrosFiltrados);
        setSearchTerm('');
        setListOrgs([]);
         onClose();
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            getUnidadesOrganizacional();
        }
    }
    const columns = Columns();
    return (
        <div>
            <BootstrapDialog
                fullWidth={false}
                maxWidth='lg'
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: colors.appColor['primary'],
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Unidade Organizacional
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handClose} 
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', marginBottom: 1 }}>
                        <Paper
                            component="div"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500, backgroundColor: colors.appColor['secondary'] }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Pesqusiar..."
                                inputProps={{ 'aria-label': 'Pesqusiar...' }}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={keyPress}
                                autoFocus
                            />
                            <IconButton type="button" sx={{ p: '10px' }}
                                aria-label="search"
                                onClick={getUnidadesOrganizacional}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </Box>
                    <Box
                        height="50vh"

                        sx={StyleGrid}
                    >
                        {listOrgs.length > 0 ? (
                            <DataGrid
                                checkboxSelection
                                rows={listOrgs}
                                columns={columns}
                                loading={loading}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setRows(newRowSelectionModel);
                                }}

                                slots={{
                                    noRowsOverlay: CustomNoRowsOverlay,
                                }} />
                        ) : (
                            <div className="box-container">
                                <div className="oval-box">
                                    <p className="centered-text">Você deve realizar uma pesquisa.</p>
                                </div>
                            </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handClose} variant="contained">
                        Fechar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdicionar}
                        sx={{ marginLeft: 'auto' }}
                    >
                        Adicionar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
});

export default ModalCursoD2L;