import { service } from "../services";
import routes from "../services/routes";

export const courseController = {
  getCursos: async (query) => {
    try {
      const rota = routes.curso + query;

      const result = await service.get(rota);
      return result;
    } catch (error) {
      throw error;
    }
  },
  getOfertasCursos: async (query) => {
    try {
      const rota = routes.ofertasCurso + query;

      const result = await service.get(rota);
      return result;
    } catch (error) {
      throw error;
    }
  },
  novoCurso: async (body) => {
    try {
      const rota = routes.novoCurso;

      const result = await service.post(rota, body);
      return result;
    } catch (error) {
      throw error;
    }
  },
  deleteCurso: async (body) => {
    try {
      const rota = routes.deletaCurso;

      const result = await service.delete(rota, body);
      return result;
    } catch (error) {
      throw error;
    }
  },
  alterarStatusCurso: async (body) => {
    try {
      const rota = routes.alteraStatusCurso;

      const result = await service.put(rota, body);
      return result;
    } catch (error) {
      throw error;
    }
  },
  atualizarCurso: async (id, body) => {
      try {
          const rota = routes.atualizarCurso;
          const novoBody = {
            IdCurso: id,
              ...body}
          const result = await service.put(rota, novoBody);
          return result;
      } catch (error) {
          throw error;
      }
  },
  getUsuarios: async (query) => {
    try {
      const rota = routes.usuariosCursos + query;

      const result = await service.get(rota);
      return result;
    } catch (error) {
      throw error;
    }
  },
};