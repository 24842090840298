import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box, useTheme, TextField,
    Button, IconButton,
} from "@mui/material";
import { tokens } from "../theme";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const ModalProrrogarAcesso = React.memo(({ onOpen, onClose, onGetDias}) => {
  
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [duracaoCurso, setDuracaoCurso] = useState();
    
    const handleInputDuracaoChange = (event) => {
        const inputValue = event.target.value;
        if (!isNaN(inputValue)) {
            setDuracaoCurso(inputValue);
        }
    };

    const handleClick = () => {
        if (!isNaN(duracaoCurso)) {
        onGetDias(duracaoCurso);
        setDuracaoCurso();
        onClose();
        }
    }

    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={onOpen}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: colors.appColor['primary'],
                    },
                }}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Prorrogar Acesso
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose} // Alterado para usar a propriedade onClose diretamente
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.appColor['branca'],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        >
                           

                            <TextField
                                fullWidth
                                label="Duração em Dias"
                                margin="normal"
                                variant="outlined"
                                sx={{
                                    gridColumn: "span 2",
                                    '& label.Mui-focused': {
                                        color: 'white',
                                    },
                                    '& .MuiOutlinedInput-root.Mui-focused': {
                                        borderColor: 'white',
                                    },
                                }}
                                value={duracaoCurso}
                                onChange={handleInputDuracaoChange}
                            />

                        </Box>                    
                   
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onClose} variant="contained">
                        Fechar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        sx={{ color: colors.appColor['branca'], backgroundColor:colors.appColor['secondary'] }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
});

export default ModalProrrogarAcesso;
