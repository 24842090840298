import { service } from "../services";
import routes from "../services/routes";

export const parceiroController = {
    getParceiros: async (query) => {
        try {
            const rota = routes.parceiro + query;

            const result = await service.get(rota);
            return result;
        } catch (error) {
            throw error;
        }
    },
    novoParceiro: async (body) => {
        try {
            const rota = routes.novoParceiro;

            const result = await service.post(rota, body);
            return result;
        } catch (error) {
            throw error;
        }
    },
    alterarStatusParceiro: async (body) => {
        try {
            const rota = routes.alteraStatusParceiro;

            const result = await service.put(rota, body);
            return result;
        } catch (error) {
            throw error;
        }
    },
    deletarParceiro: async (body) => {
        try {
            const rota = routes.deletaParceiro;

            const result = await service.delete(rota, body);
            return result;
        } catch (error) {
            throw error;
        }
    },
    atualizarParceiro: async (id, body) => {
        try {
            const rota = routes.atualizarParceiro;
            const novoBody = {
                id: id,
                ...body}
            const result = await service.put(rota, novoBody);
            return result;
        } catch (error) {
            throw error;
        }
    },
};