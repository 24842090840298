const Columns = () => {
    const columns = [{
        field: "id",
        headerName: "Id",
        minWidth: 130,
        cellClassName: "name-column--cell",
    },
    {
        field: "code",
        headerName: "Código",
        minWidth: 130,
        cellClassName: "name-column--cell",
    },
    {
        field: "name",
        headerName: "Nome",
        minWidth: 200,
        cellClassName: "name-column--cell",
    },
    {
        field: "type.code",
        headerName: "Tipo",
        minWidth: 120,
        cellClassName: "name-column--cell",
        valueGetter: (params) => params.row.type.code,
    },
    ];

    return columns;
};

export default Columns;